import { closeCamera } from "@privateid/cryptonets-web-sdk";
import { ELEMENT_ID } from "constant";
import { UserContext } from "context/userContext";
import { ArrowLeft } from "lucide-react";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useNavigation } from "utils/onNavigate";

type Props = {
  isCamera?: boolean;
};

function BackButton(Props: Props) {
  const context = useContext(UserContext);
  const pageConfig: any = context?.pageConfiguration;
  const { navigateToNextPage } = useNavigation(true);
  const navigate = useNavigate();
  return (
    <div
      className="flex justify-center items-center cursor-pointer absolute top-0 left-0 z-[99999]"
      onClick={async () => {
        if (Props?.isCamera) {
          await closeCamera(ELEMENT_ID);
          navigate(-1);
        } else {
          if (pageConfig) {
            navigateToNextPage();
          } else {
            navigate(-1);
          }
        }
      }}
    >
      <ArrowLeft />
    </div>
  );
}

export default BackButton;
