import { UserContext } from "context/userContext";
import { X } from "lucide-react";
import { useContext } from "react";
import { extractRoute } from "utils";
import { useNavigateWithQueryParams } from "../../../utils/navigateWithQueryParams";

type Props = {} | any;

function CloseButton(Props: Props) {
  const { navigateWithQueryParams } = useNavigateWithQueryParams();
  const {
    configuration,
    setPageConfiguration,
    setPageIndex,
    setLoginOption,
  }: any = useContext(UserContext);
  const loadPageConfiguration = () => {
    const currentRouteIndex = configuration?.appPages?.findIndex(
      (item: any) => item?.currentRoute === "/"
    );
    setPageIndex(currentRouteIndex);
    const pageConfiguration = extractRoute(configuration, currentRouteIndex);
    setLoginOption("");
    setPageConfiguration(pageConfiguration);
  };
  return (
      <div
        className="flex justify-center items-center cursor-pointer top-0 right-0 absolute  z-[99999]"
        onClick={() => {
          loadPageConfiguration();
          if (Props?.onClick) {
            Props?.onClick();
          } else {
            navigateWithQueryParams("/", { token: undefined });
          }
        }}
      >
        <X />
      </div>
  );
}

export default CloseButton;
