import { useContext, useRef } from "react";
import { Button } from "../../components/ui/button";
import { Label } from "../../components/ui/label";
import { RadioGroup } from "../../components/ui/radio-group";
import Layout from "../layout";
import BackButton from "../components/backButton";
import CloseButton from "common/components/closeButton";
import { UserContext } from "context/userContext";
import { useNavigation } from "utils/onNavigate";
import SignatureCanvas from "react-signature-canvas";
import { isMobile } from "utils";

function ElectronicSignature() {
  const context = useContext(UserContext);
  const sigCanvas = useRef<any>({});
  const pageConfig: any = context?.pageConfiguration;
  const { navigateToNextPage } = useNavigation();

  // Function to clear the signature
  const clearSignature = () => {
    sigCanvas.current.clear();
  };
  return (
    <Layout>
      <div className="relative flex text-center justify-center">
        <div className="relative w-full">
          <RadioGroup defaultValue="drivers-licence">
            <div className="px-10 py-8 max-md:p-[20px]">
              <div className="flex justify-between relative">
                <BackButton />
                <CloseButton />
              </div>
              <div className="mt-2">
                <Label className="text-[28px] font-[500] text-primaryText max-md:text-[24px]">
                  {pageConfig?.title || "I-9 Employment Verification"}{" "}
                </Label>
              </div>
              <div className="text-center overflow-auto h-[490px] mt-2 p-1">
                <div className="ps-8 pe-8">
                  <Label className="text-[14px] font-[400] text-secondaryText">
                    {pageConfig?.subheading ||
                      "Required for employment in the United States"}{" "}
                  </Label>
                </div>
                <div className="mt-2 relative">
                  <div>
                    <Label className="text-[28px] font-[500] text-primaryText text-left max-md:text-[24px] block">
                      Draw your signature
                    </Label>
                    <div
                      className="relative"
                      style={{ width: isMobile ? 300 : 500, margin: "0 auto" }}
                    >
                      <SignatureCanvas
                        penColor="black"
                        canvasProps={{
                          width: isMobile ? 300 : 500,
                          height: 200,
                          className: "sigCanvas",
                        }}
                        ref={sigCanvas}
                      />
                      <button
                        onClick={clearSignature}
                        className="absolute top-0 right-[-2px] border border-[#ccc] pr-[10px] pl-[10px]"
                      >
                        Clear
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </RadioGroup>
        </div>
      </div>
      <div className="text-left h-[100px] absolute w-full bottom-0 rounded-b-[24px] ps-10 pe-10 pt-6 pb-4 max-md:h-[unset]">
        <div>
          <Button
            className="w-full text-white bg-primary rounded-[24px] mt-4 hover:opacity-90 hover:bg-primary"
            onClick={() => {
              if (pageConfig) {
                navigateToNextPage();
              }
            }}
          >
            {pageConfig?.buttonText || "Start I-9 Form"}
          </Button>
        </div>
      </div>
    </Layout>
  );
}

export default ElectronicSignature;
