import { useContext } from "react";
import { UserContext } from "context/userContext";
import Layout from "common/layout";
import BackButton from "common/components/backButton";
import CloseButton from "common/components/closeButton";
import { Label } from "components/ui/label";
import { Button } from "components/ui/button";
import { useNavigation } from "utils/onNavigate";

function PdfViewer() {
  const context = useContext(UserContext);
  const pageConfig: any = context?.pageConfiguration;
  const { navigateToNextPage } = useNavigation();

  return (
    <>
      <Layout removeHeight={true}>
        <div className="px-10 py-8 max-md:p-[20px] max-md:pt-[20px]">
          <div className="flex justify-between relative">
            <BackButton />
            <CloseButton />
          </div>
          <div className="mt-2">
            <Label className="text-[28px] font-[500] text-primaryText max-md:text-[24px]">
              {pageConfig?.title || "I-9 Employment Eligibility Verification"}{" "}
            </Label>
          </div>
          <div className="mt-[10px] rounded-[20px] flex flex-col items-center justify-center">
            <iframe src="https://www.uscis.gov/sites/default/files/document/forms/i-9.pdf" className="w-full h-[470px] mb-[50px] max-md:h-[unset]" title="pdf" />
          </div>
          <div className="text-left h-[100px] left-0 absolute w-full bottom-0 rounded-b-[24px] ps-10 pe-10 pt-6 pb-4 max-md:h-[unset]">
            <div>
              <Button
                className="w-full text-white bg-primary rounded-[24px] mt-4 hover:opacity-90 hover:bg-primary"
                onClick={() => {
                  if (pageConfig) {
                    navigateToNextPage();
                  }
                }}
              >
                Next
              </Button>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}

export default PdfViewer;
