import { GearIcon } from "@radix-ui/react-icons";
import userGrey from "assets/userAvatarGrey.png";
import helthGrey from "assets/healthGrey.png";
import calendarGrey from "assets/calendarGrey.png";
import { Label } from "components/ui/label";
// @ts-ignore: Unreachable code error
import RangeSlider from "react-range-slider-input";
import "react-range-slider-input/dist/style.css";
import { Input } from "components/ui/input";
import { Button } from "components/ui/button";
import {
  extractRoute,
  formatTime,
  formatTimeoutLabel,
  isMobile,
  sliceUuid,
  timeLabel,
} from "utils";
import { Popover, PopoverContent, PopoverTrigger } from "components/ui/popover";
import { RadioGroup, RadioGroupItem } from "components/ui/radio-group";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "context/userContext";
import { useNavigation } from "utils/onNavigate";
import { Checkbox } from "components/ui/checkbox";

type props = {
  setConfig: (data: { value: string; retries?: string }) => void;
  config: { value: string; retries?: string };
  animation: boolean;
  backgroundColor: string;
  seconds: number;
  predictSuccess: boolean;
  range: number[];
  onRangeChange: React.SyntheticEvent | Event | any;
  maxSeconds: number;
  handleThumbDragEnd: React.SyntheticEvent | Event | any;
  handleRangeDragEnd: React.SyntheticEvent | Event | any;
  setIsAnimation: (value: boolean) => void;
};
function ContinuousAuthSuccess(props: props) {
  const {
    setConfig,
    config,
    animation,
    backgroundColor,
    seconds,
    predictSuccess,
    range,
    onRangeChange,
    maxSeconds,
    handleThumbDragEnd,
    handleRangeDragEnd,
    setIsAnimation,
  } = props;
  const context: any = useContext(UserContext);
  const configuration: any = context?.configuration;
  const [rangeSlider, setRangeSlider] = useState(true);
  const [isTransition, setIsTransition] = useState(true);
  const { navigateToNextPage } = useNavigation();
  const uuid = JSON.parse(localStorage.getItem("uuid") || "{}");
  const loadPageConfiguration = () => {
    const currentRouteIndex = configuration?.appPages?.findIndex(
      (item: any) => item?.currentRoute === "/"
    );
    const activeIndex = currentRouteIndex;
    context?.setPageIndex(activeIndex);
    const pageConfiguration = extractRoute(configuration, activeIndex);
    context?.setPageConfiguration(pageConfiguration);
  };

  useEffect(() => {
    if (config?.value === "silentLogout" && isTransition) {
      reRenderSlider();
    }
  }, [animation]);

  const reRenderSlider = () => {
    setRangeSlider(false);
    setTimeout(() => {
      setRangeSlider(true);
    }, 400);
  };

  const onLogout = () => {
    localStorage.removeItem("uuid");
    localStorage.removeItem("user");
    loadPageConfiguration();
    navigateToNextPage("", "/", "/continuous-authentication-v2");
  };

  const _renderScreen = () => {
    return (
      <div className={`relative mt-[-20px] max-md:mt-[10px]`}>
        <div className="flex items-center mb-[10px]">
          <p
            className={`text-[${backgroundColor}] text-[30px] text-right max-md:mt-[0px]  max-md:text-[20px] ml-auto`}
            style={{ color: backgroundColor }}
          >
            {formatTime(seconds)}
          </p>
        </div>
        <div className="bg-[#fff] p-[20px] rounded-[20px] shadow-lg h-[150px] flex items-center justify-center">
          <p className="text-[#ccc] text-[23px]">
            Welcome, <span className="text-[green]">{sliceUuid(uuid)}</span>
          </p>
        </div>
        <div className="flex items-center justify-between mt-[30px]  max-md:block mb-[20px]">
          <div className="bg-[#fff] p-[20px] rounded-[20px] shadow-lg h-[150px] w-[30%]  max-md:w-[100%]  max-md:mb-[30px] flex flex-col items-center justify-center">
            <img
              src={userGrey}
              className="w-[100px] m-auto mb-[10px] h-[60px] object-contain mt-[0px]"
              alt="user"
            />
            <p className="text-[#000] text-[13px] text-center">Me</p>
          </div>
          <div className="bg-[#fff] p-[20px] rounded-[20px] shadow-lg h-[150px] w-[30%]  max-md:w-[100%]  max-md:mb-[30px] flex flex-col items-center justify-center">
            <img
              src={calendarGrey}
              className="w-[100px] m-auto mb-[10px] h-[60px] object-contain mt-[0px]"
              alt="calendar"
            />
            <p className="text-[#000] text-[13px] text-center">My Shift</p>
          </div>
          <div className="bg-[#fff] p-[20px] rounded-[20px] shadow-lg h-[150px] w-[30%]  max-md:w-[100%]  max-md:mb-[30px] flex flex-col items-center justify-center">
            <img
              src={helthGrey}
              className="w-[100px] m-auto mb-[10px] h-[60px] object-contain mt-[0px]"
              alt="health"
            />
            <p className="text-[#000] text-[13px] text-center">Health</p>
          </div>
        </div>
        {rangeSlider && (
          <>
            <div
              className="flex items-center mt-[10px]"
              style={{ marginTop: predictSuccess && !isMobile ? "10rem" : 0 }}
            >
              <div className="group relative flex justify-center w-[100px]">
                Prediction Interval
                {!animation && (
                  <span className="absolute w-[200px] bottom-10 scale-0 max-md:left-[10px] max-md:bottom-7 transition-all rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100">
                    {/*Your session will start after a {formatTimeoutLabel(range?.[0])}{" "}*/}
                    Interval of repetitive prediction checks. Session active
                    until timer expires.
                  </span>
                )}
              </div>
              <div className="text-[#aaa] justify-center w-[100%] flex items-center">
                UUID: {uuid?.length && uuid}
              </div>
              {/*<div></div>*/}
              <div className="group relative flex justify-center  w-[100px]">
                Timeout
                {!animation && (
                  <span className="absolute w-[200px] bottom-10 scale-0 transition-all max-md:right-[5px] max-md:bottom-7 rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100">
                    Your session will expire in{" "}
                    {formatTimeoutLabel(range?.[1])}{" "}
                    {timeLabel(range?.[1], false)} unless the authenticated
                    user successfully predicts during the Prediction Interval.
                  </span>
                )}
              </div>
            </div>
            <div className="mt-[10px] flex items-center mb-[20px]">
              <div className="bg-[#d5d5d5] flex text-[#333] w-[100px] h-[50px] text-[16px] items-center justify-center">
                {formatTimeoutLabel(range?.[0])} {timeLabel(range?.[0], true)}
              </div>
              <div className="bg-[#f9f9f9] w-[100%] px-[20px] h-[50px] flex items-center">
                <RangeSlider
                  value={range}
                  onInput={onRangeChange}
                  max={maxSeconds}
                  onThumbDragEnd={handleThumbDragEnd}
                  onRangeDragEnd={handleRangeDragEnd}
                />
              </div>
              <div className="bg-[#d5d5d5] flex text-[#333] w-[100px] h-[50px] text-[16px] items-center justify-center">
                {formatTimeoutLabel(range?.[1])} {timeLabel(range?.[1], true)}
              </div>
            </div>
          </>
        )}
      </div>
    );
  };
  const front: any = document.getElementById("front");
  const side: any = document.getElementById("side");
  const isActive =
    front?.classList?.contains("active") && side?.classList?.contains("active");
  function getClassName(
    isTransition: boolean,
    isActive: boolean,
    animation: boolean
  ): string {
    if (!isTransition && !isActive) {
      setIsAnimation(false);
      return "";
    } else if (!isTransition && isActive) {
      setIsAnimation(false);
      return "active";
    } else if ((!isTransition && !animation) || (!isTransition && !isActive)) {
      return "";
    } else if (!isTransition) {
      return "active";
    } else if (animation) {
      return "active";
    }
    return "";
  }
  return (
    <>
      <Popover>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            size={"icon"}
            className="shadow-lg fixed right-44 top-5 max-md:top-[-2.8rem] max-md:absolute max-md:left-[0px] text-[14px] hover:opacity-90 hover:bg-primary rounded-[10px] hover:text-white max-md:w-[100%] max-md:text-[13px] max-md:max-w-[80px]"
          >
            <GearIcon className="h-4 w-4" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-60">
          <div className="grid gap-4">
            <div className="space-y-2">
              <h4 className="font-medium leading-none">Configuration</h4>
              <p className="text-sm text-muted-foreground">
                Set the configuration parameters.
              </p>
            </div>
            <div className="grid gap-2">
              <RadioGroup
                onValueChange={(value: string) =>
                  setConfig({
                    value,
                    retries: value === "afterInterval" ? "3" : undefined,
                  })
                }
                defaultValue={config?.value}
              >
                {/* <div className="flex items-center space-x-2">
                       <RadioGroupItem value="immediately" id="option-one" />
                       <Label htmlFor="option-one">Logout Immediately</Label>
                     </div> */}
                <div className="flex items-center space-x-2">
                  <RadioGroupItem value="silentLogout" id="option-two" />
                  <Label htmlFor="option-two">Silent Signin</Label>
                </div>
                <div className="flex items-center space-x-2">
                  <RadioGroupItem value="afterInterval" id="option-two" />
                  <Label htmlFor="option-two">Signin with Interval</Label>
                </div>
              </RadioGroup>
              {config.value === "afterInterval" ? (
                <div className="grid grid-cols-3 items-center gap-4">
                  <Label htmlFor="height">Retries</Label>
                  <Input
                    id="retries"
                    defaultValue="3"
                    onChange={(e: any) =>
                      setConfig({ ...config, retries: e?.target.value })
                    }
                    className="col-span-2 h-8"
                  />
                </div>
              ) : null}
              <div
                className="flex"
                onClick={() => setIsTransition(!isTransition)}
              >
                <Checkbox
                  id="terms1"
                  className="mt-[4px] me-2"
                  onChange={() => {
                    setIsTransition(!isTransition);
                  }}
                  checked={isTransition}
                />
                <div onClick={() => setIsTransition(!isTransition)}>
                  <Label className="text-[14px] font-[500] cursor-pointer">
                    Show transition
                  </Label>
                </div>
              </div>
            </div>
          </div>
        </PopoverContent>
      </Popover>
      <Button
        className="w-[130px] bg-primary border-2 fixed right-8 top-5 max-md:top-[-2.8rem] max-md:absolute max-md:right-[0px] border-solid border-primary rounded-[24px] text-[14px] hover:opacity-90 hover:bg-primary text-[#fff] hover:text-white max-md:w-[100%] max-md:text-[13px] max-md:max-w-[80px] ml-[10px]"
        onClick={onLogout}
      >
        Logout
      </Button>
      <div id="experiment">
        <div id="cube" className="cube">
          <div
            id="front"
            className={`face front ${getClassName(
              isTransition,
              isActive,
              animation
            )}`}
          >
            {_renderScreen()}
          </div>
          <div
            id="side"
            className={`face side ${getClassName(
              isTransition,
              isActive,
              animation
            )}`}
          >
            {_renderScreen()}
          </div>
        </div>
      </div>
    </>
  );
}

export default ContinuousAuthSuccess;
