import { useContext } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { UserContext } from "context/userContext";
import { loginOptions } from "constant";

interface NavigationParams {
  [key: string]: string;
}
interface AppPage {
  currentRoute: string;
  type: string;
  id: string;
}

export const useNavigation = (isBack?: boolean) => {
  const userContext = useContext(UserContext);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const navigateWithQueryParams = (
    routePath: string,
    newQueryParams: NavigationParams = {}
  ) => {
    const currentSearchParams = Object.fromEntries(
      new URLSearchParams(searchParams)
    );
    const combinedQueryParams = { ...currentSearchParams, ...newQueryParams };
    const queryString = new URLSearchParams(combinedQueryParams).toString();
    navigate(`${routePath}?${queryString}`);
  };

  const getCurrentPage = (index?: number) => {
    const configuration: any = userContext?.configuration;
    const pageIndex = index !== undefined ? index : userContext?.pageIndex || 0;

    const nextPage = configuration?.appPages?.[pageIndex];
    return nextPage;
  };

  const navigateToNextPage = (
    params?: string,
    route?: string,
    prevRoute?: any
  ) => {
    const configuration: any = userContext?.configuration;
    let currentRouteIndex =
      configuration?.appPages?.findIndex(
        (item: AppPage) => item?.currentRoute === route
      ) ?? -1;
    const prevRouteIndex =
      configuration?.appPages?.findIndex(
        (item: AppPage) => item?.currentRoute === prevRoute
      ) ?? -1;
    if (!isBack && loginOptions?.includes(userContext?.loginOption)) {
      const allScreens = configuration?.appPages?.filter(
        (item: any) => item?.currentRoute === route
      );
      const loginScreen = allScreens?.find(
        (item: any) => item?.type === "login"
      );
      currentRouteIndex = configuration?.appPages?.findIndex(
        (item: any) =>
          item?.id === loginScreen?.id && item?.type === loginScreen?.type
      );
    }
    const currentIndex =
      currentRouteIndex > -1
        ? currentRouteIndex - 1
        : userContext?.pageIndex || 0;
    const nextIndex = isBack
      ? userContext?.pagePrevIndex !== undefined
        ? userContext?.pagePrevIndex
        : currentIndex - 1
      : currentIndex + 1;

    const page = getCurrentPage(nextIndex);
    // console.log(page, "page", route);

    if (isBack) {
      const routes = [...userContext?.previousRoutes];
      const previousRoute = routes[routes.length - 1];
      routes.pop();
      userContext.setPreviousRoutes(routes);
      const prevRouteIndex =
        configuration?.appPages?.findIndex(
          (item: AppPage) => item?.currentRoute === previousRoute
        ) ?? -1;
      userContext?.setPrevPageIndex(undefined);
      userContext?.setPageIndex(prevRouteIndex);
      const page = getCurrentPage(prevRouteIndex);
      userContext?.setPageConfiguration(page);
      if (params) {
        navigate(route ? `${route}?${params}` : `${previousRoute}?${params}`);
      } else {
        navigateWithQueryParams(route ? route : previousRoute);
      }
      return;
    } else if (prevRouteIndex > -1) {
      userContext?.setPrevPageIndex(prevRouteIndex);
    }
    if (!isBack) {
      userContext.setPreviousRoutes([
        ...userContext?.previousRoutes,
        window.location.pathname,
      ]);
    }
    if (page) {
      // if (isBack) {

      // } else {
      //   userContext?.setUserRoutes([
      //     ...userContext?.userRoutes,
      //     page?.currentRoute,
      //   ]);
      // }
      if (route !== '/') {
        userContext?.setPageIndex(nextIndex);
        userContext?.setPageConfiguration(page);
      }
      if (!page.enable) {
        const bypassIndex = isBack
          ? userContext?.pagePrevIndex || currentIndex - 2
          : currentIndex + 2;
        userContext?.setPageIndex(bypassIndex);

        const nextPage = getCurrentPage(bypassIndex);

        if (nextPage) {
          if (params) {
            navigate(
              route
                ? `${route}?${params}`
                : `${nextPage?.currentRoute}?${params}`
            );
          } else {
            navigateWithQueryParams(route ? route : nextPage.currentRoute);
          }
        }
        return;
      }

      if (params) {
        navigate(
          route ? `${route}?${params}` : `${page?.currentRoute}?${params}`
        );
      } else {
        navigateWithQueryParams(route ? route : page?.currentRoute);
      }
    }
  };

  return { navigateToNextPage };
};
