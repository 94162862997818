import { Label } from "../../components/ui/label";
import { Button } from "../../components/ui/button";
import { UserContext } from "context/userContext";
import { useContext, useEffect, useState } from "react";
import { useNavigation } from "utils/onNavigate";
import PinInput from "react-pin-input";
import { isMobile } from "utils";

type Props = {
  setStep: (e: number) => void;
};

const PhoneOtp = (props: Props) => {
  const { setStep } = props;
  const [disable, setDisable] = useState<boolean>(false);
  const { navigateToNextPage } = useNavigation();
  const [completed, setCompleted] = useState(false);
  const [isOtp, setIsOtp] = useState(false);
  const onRegister = async () => {
    setIsOtp(true);
    if (isOtp) {
      setCompleted(true);
    }
  };
  const [pin, setPin] = useState("");
  const [wrongPin, setWrongPin] = useState(false);
  const handlePinOnComplete = (value: string) => {
    setPin(value);
  };

  useEffect(() => {
    if (completed && isOtp) {
      setTimeout(() => {
        navigateToNextPage("", "/", "/login-mobile-otp");
      }, 2000);
    }
  }, [completed]);
  return (
    <div
      className={`pt-[60px] bg-[#595959] h-full overflow-hidden ${
        completed ? "flex flex-col items-center justify-center !pt-[0px]" : ""
      }`}
    >
      {completed ? (
        <>
          <div className="bg-[#cccccc80] w-[100%] h-[100%] flex items-center justify-center successAnimationNew">
            <div className="text-[25px] absolute top-[62px] left-[38px] z-[99999999] w-full bg-transparent bg-opacity-70 text-white text-left py-2 rounded-lg">
              Successs
            </div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="svg-success"
              viewBox="0 0 24 24"
            >
              <g
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-miterlimit="10"
              >
                <circle
                  className="success-circle-outline"
                  cx="12"
                  cy="12"
                  r="11.5"
                />
                <circle
                  className="success-circle-fill"
                  cx="12"
                  cy="12"
                  r="11.5"
                />
                <polyline
                  className="success-tick"
                  points="17,8.5 9.5,15.5 7,13"
                />
              </g>
            </svg>
          </div>
        </>
      ) : (
        <>
          <Label className="text-[25px] font-[Google Sans] font-[500] text-[#fff] block text-center">
            Check your phone
          </Label>
          {/* <div className="flex justify-center mt-0 h-[260px]">
        <img src={loginFrame} alt="" width={500} />
      </div> */}
          <div className="flex flex-col w-[80%] m-auto max-md:w-[100%] max-md:p-[20px] mt-[20px]">
            <Label className="mt-[0px] text-[15px] font-[Google Sans] font-[100] text-white">
              {isOtp
                ? `A text message with a link was just sent to the phone ending in
                    XXXX. Click on the link in the message to complete registration by
                    entering the passcode below.`
                : `A text message with a link was just sent to the phone ending in XXXX. Click on the link in the message to complete registration. 
                    `}
            </Label>
          </div>
          {isOtp && (
            <div className="relative flex justify-center items-center pt-[30px]">
              <PinInput
                length={6}
                initialValue=""
                focus
                type="numeric"
                inputMode="number"
                style={{ padding: "10px" }}
                ariaLabel={`pin`}
                inputStyle={{
                  borderColor: "gray",
                  width: isMobile ? "35px" : "45px",
                  height: isMobile ? "35px" : "45px",
                  fontSize: isMobile ? "20px" : "24px",
                  marginLeft: 10,
                  transition: "all .2s",
                  color: "#fff",
                }}
                inputFocusStyle={{
                  borderColor: "#5283EC",
                  transform: "scale(1.1)",
                }}
                onChange={(e) => {
                  setWrongPin(false);
                }}
                onComplete={(value) => {
                  handlePinOnComplete(value);
                }}
                autoSelect={true}
                regexCriteria={/^[0-9]*$/}
              />
            </div>
          )}

          <div className="mt-[50px] flex justify-center max-md:mt-16">
            <Button
              className="w-[65%] bg-primary rounded-[4px] text-[14px] hover:opacity-90 hover:bg-primary border-2 border-primary text-white"
              onClick={() => onRegister()}
              disabled={disable || (pin?.length !== 6 && isOtp)}
            >
              {isOtp ? "Submit" : "Re-send Text Message"}
            </Button>
          </div>
          <div
            className="mt-6 flex justify-center max-md:mt-2"
            onClick={() => setStep(2)}
          >
            <Button className="w-[65%] underline bg-transparent rounded-[24px] text-[14px] hover:opacity-90 hover:bg-transparent border-2 border-transparent text-white">
              I did not receive the text
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default PhoneOtp;
