import { useState } from "react";
import { useSearchParams } from "react-router-dom";
type Props = {
  liveness?: boolean;
  show?: boolean;
};
const LivenessSwitch = ({ liveness, show }: Props) => {
  const [livenessValue, setLivenessValue] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const skipAntiSpoof = searchParams.get("skipAntispoof") !== "false";
  const handleChange = () => {
    const skipAntiSpoof = searchParams.get("skipAntispoof") !== "false";
    const token = searchParams.get("token") || "";
    setSearchParams({
      token,
      skipAntispoof: (!skipAntiSpoof).toString(),
    });
    setLivenessValue(!livenessValue);
  };
  return (
      show ? (
      <label className="flex items-center justify-end cursor-pointer mr-2">
        <span className="mr-3 text-sm font-medium text-gray-900 dark:text-gray-300">
          Liveness
        </span>
        <input
          type="checkbox"
          checked={livenessValue}
          onChange={handleChange}
          className="sr-only peer"
        />
        <div
          className={`relative w-11 h-6 bg-gray-200 peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full ${
            !skipAntiSpoof ? "!bg-primary" : "bg-gray-400"
          }`}
        >
          <div
            className={`absolute w-5 h-5 bg-white rounded-full transition-transform peer-checked:transform peer-checked:translate-x-full rtl:peer-checked:-translate-x-full ${
              !skipAntiSpoof ? "translate-x-full" : ""
            } top-[2.2px] left-[2px]`}
          ></div>
        </div>
      </label>
    ) : null
  );
};

export default LivenessSwitch;
