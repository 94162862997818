import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "../../../components/ui/alertDialog";
function SwitchUserModal(props: {
  showSwitchUser: boolean;
  onSwitchUser: () => void;
}) {
  const { showSwitchUser, onSwitchUser } = props;
  return (
    <AlertDialog open={showSwitchUser}>
      <AlertDialogTrigger aria-label="alert-trigger" />
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>New User Detected</AlertDialogTitle>
          <AlertDialogDescription>
            Are you sure you want to switch to the new user session?
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogAction
            className="justify-center hover:opacity-90 hover:bg-primary"
            onClick={() => {
              onSwitchUser();
            }}
          >
            Switch User
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}

export default SwitchUserModal;
