import loginFrame from "../../assets/login/login-main-frame.svg";
import { Label } from "../../components/ui/label";
import { Button } from "../../components/ui/button";
import { Layout } from "../index";
import BackButton from "common/components/backButton";
import CloseButton from "common/components/closeButton";
import { UserContext } from "context/userContext";
import { useContext } from "react";
import { useNavigation } from "utils/onNavigate";

const Authorized = () => {
  const { navigateToNextPage } = useNavigation();
  const context: any = useContext(UserContext);
  const pageConfig: any = context?.pageConfiguration;
  const onRegister = async () => {
    navigateToNextPage();
  };
  return (
    <Layout>
      <div className="relative flex text-center justify-center">
        <div className="relative w-full">
          <div className="px-10 py-8 max-md:p-[20px]">
            <div className="flex justify-between relative">
              <BackButton />
              <div className="flex items-center justify-center w-full" />
              <div>{/* {Empty div to adjust space} */}</div>
              <CloseButton />
            </div>
            <div className="mt-2">
              <Label className="text-[28px] font-[500] text-primaryText">
                {pageConfig?.title || `Authorize Private Identity`}
              </Label>
            </div>
            <div className="flex justify-center mt-0">
              <img src={loginFrame} alt="" width={400} />
            </div>
            <div className="flex flex-col w-[80%] m-auto max-md:w-[100%] max-md:p-[20px]">
              <Label className="mt-[15px] text-[15px] font-[Google Sans] font-[100] text-secondaryText">
                Before we send you back to <b>PRIVATE IDENTITY</b>, we need your
                permission to share your verified identity information.
              </Label>
              <Label className="mt-[15px] text-[15px] font-[Google Sans] font-[100] text-secondaryText">
                Please note that only information obtained from the verification
                process will be shared
              </Label>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-10 flex justify-center max-md:mt-16">
        <Button
          className="w-[65%] bg-primary rounded-[24px] text-[14px] hover:opacity-90 hover:bg-primary text-white"
          onClick={() => onRegister()}
        >
          {pageConfig?.buttonText || "Allow"}
        </Button>
      </div>
      <div className="mt-4 flex justify-center max-md:mt-2">
        <Button className="w-[65%] bg-primary rounded-[24px] text-[14px] hover:opacity-90 hover:bg-primary text-white">
          {pageConfig?.secondaryButton || "Deny"}{" "}
        </Button>
      </div>
    </Layout>
  );
};

export default Authorized;
