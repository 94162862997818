import { useState } from "react";
import { faceLogin } from "@privateid/cryptonets-web-sdk";
import { getStatusMessage } from "@privateid/cryptonets-web-sdk/dist/utils";
import { useSearchParams } from "react-router-dom";

// Type definitions for the callback result
type PredictResult = {
  guid?: string;
  puid?: string;
  antispoof_status: number;
  face_validation_status: number;
  mf_token?: string;
};

// Type definition for the hook's parameters
interface UseMultiframePredictProps {
  onSuccess: () => void;
}

let mf_count = 3;
let loop = true;
let max_spoof_attempt = 5;
let spoof_attempt = 0;
const useMultiframePredict = ({ onSuccess }: UseMultiframePredictProps) => {
  const [multiframePredictMessage, setPredictMessage] = useState<string>("");
  const [multiframePredictAntispoofPerformed, setPredictAntispoofPerformed] =
    useState<string>("");
  const [multiframePredictAntispoofStatus, setPredictAntispoofStatus] =
    useState<number | null>();
  const [multiframePredictValidationStatus, setPredictValidationStatus] =
    useState<number | null>();
  const [searchParams] = useSearchParams();
  const [progress, setProgress] = useState<number>(0);
  const [multiframePredictGUID, setPredictGUID] = useState<string>("");
  const [multiframePredictPUID, setPredictPUID] = useState<string>("");

  max_spoof_attempt = searchParams.get("spoof_attempt")
    ? Number(searchParams.get("spoof_attempt"))
    : max_spoof_attempt;
  const mf_count_override = searchParams.get("mf_count_signin")
    ? Number(searchParams.get("mf_count_signin"))
    : mf_count;
  // Type definition for the callback function
  const callback = async (result: PredictResult) => {
    console.log(
      "predict callback hook result:",
      max_spoof_attempt,
      spoof_attempt,
      JSON.stringify(result)
    );
    const token = result.mf_token;
    if (token) {
      setProgress((p) => {
        if (p >= 100) return 100;
        return p + 100 / mf_count_override;
      });
    } else {
      setProgress(0);
    }
    if (result.guid && result.puid) {
      setPredictGUID(result.guid);
      setPredictPUID(result.puid);
      setPredictAntispoofStatus(result.antispoof_status);
      setPredictValidationStatus(result.face_validation_status);
      setPredictMessage(getStatusMessage(result.face_validation_status));
      spoof_attempt = 0;
      onSuccess();
    } else if (result?.antispoof_status === 1) {
      spoof_attempt++;
      setProgress(0);
      if (spoof_attempt >= max_spoof_attempt) {
        alert("Spoof attempt exceeded");
        spoof_attempt = 0;
        // window.location.href = "/";
      } else {
        setPredictAntispoofStatus(result.antispoof_status);
        setPredictValidationStatus(result.face_validation_status);
        setPredictMessage(getStatusMessage(result.face_validation_status));
        multiframePredictUserOneFa({ mf_token: token });
      }
    } else {
      setPredictAntispoofStatus(result.antispoof_status);
      setPredictValidationStatus(result.face_validation_status);
      setPredictMessage(getStatusMessage(result.face_validation_status));
      spoof_attempt = 0;
      if (loop) {
        multiframePredictUserOneFa({ mf_token: token });
      }
    }
  };

  // Type definition for the function parameters
  interface MultiframePredictUserOneFaProps {
    mf_token?: string;
  }

  const multiframePredictUserOneFa = async ({
    mf_token,
  }: MultiframePredictUserOneFaProps) => {
    setPredictAntispoofPerformed("");
    // setPredictAntispoofStatus(null);
    // setPredictValidationStatus(null);
    setPredictGUID("");
    setPredictPUID("");

    await faceLogin({
      callback,
      config: {
        disable_predict_mf: false,
        mf_count_override,
        mf_token: mf_token || "",
        threshold_user_too_far: 0.1,
        allowed_results: [10],
      },
    });
  };

  return {
    multiframePredictUserOneFa,
    multiframePredictMessage,
    multiframePredictAntispoofPerformed,
    multiframePredictAntispoofStatus,
    multiframePredictValidationStatus,
    multiframePredictGUID,
    multiframePredictPUID,
    progress,
  };
};

export default useMultiframePredict;
