import loginFrame from "../../assets/login/login-main-frame.svg";
import { Label } from "../../components/ui/label";
import { Button } from "../../components/ui/button";
import { UserContext } from "context/userContext";
import { useContext, useState } from "react";
import { createUserWithSession } from "@privateid/cryptonets-web-sdk";

import {useNavigateWithQueryParams} from "../../utils/navigateWithQueryParams";
import {extractRoute} from "../../utils";

type Props = {
  setStep: (e: number) => void;
  loader: boolean;
};

const UserIdentity = (props: Props) => {
  const { setStep, loader } = props;
  const context: any = useContext(UserContext);
  const { navigateWithQueryParams } = useNavigateWithQueryParams();
  const [disable, setDisable] = useState<boolean>(false);
  const onRegister = async () => {
    const payload = {
      sessionToken: context.tokenParams,
      phone: "9999999999",
      ssn: "",
    };
    context.setUser({ ...context.user });
    setDisable(false);
    const userResult = await createUserWithSession(payload);
    setDisable(true);
    if (userResult?.success) {
      setStep(2);
    }
  };

  const {
    configuration,
    setPageConfiguration,
    setPageIndex,
    setLoginOption,
  }: any = useContext(UserContext);
  const loadPageConfiguration = () => {
    const currentRouteIndex = configuration?.appPages?.findIndex(
        (item: any) => item?.currentRoute === "/"
    );
    setPageIndex(currentRouteIndex);
    const pageConfiguration = extractRoute(configuration, currentRouteIndex);
    setLoginOption("");
    setPageConfiguration(pageConfiguration);
    navigateWithQueryParams("/", {token: undefined})
  };
  return (
    <div className="pt-[60px] bg-[#ffffff0a] min-h-screen flex flex-col justify-between">
      <div className="flex-grow">
        <Label className="text-[25px] font-[Google Sans] font-[500] text-[#cbd4eb] block text-center">
          Take a selfie to register
        </Label>
        <Label className="text-[15px] font-[Google Sans] font-[400] text-[#cbd4eb] block text-center">
          No images will leave your device.
        </Label>
        <div className="flex flex-col w-[80%] m-auto max-md:w-[100%] max-md:p-[20px]">
          <h5 className="text-[20px] text-[#cbd4eb] mt-[100px] font-[600] max-md:mt-[50px]">
            USER CONSENT
          </h5>
          <Label className="mt-[0px] text-[15px] font-[Google Sans] font-[100] text-[#cbd4eb]">
            By clicking the 'Agree and continue' button below, you acknowledge
            that you are over eighteen (18) years of age, have read the Prove
            LLC and Private Identity LLC{" "}
            <a href="/" className="underline">
              Privacy Policy
            </a>{" "}
            and{" "}
            <a href="/" className="underline">
              Terms of Use
            </a>{" "}
            and understand how your personal data will be processed in
            connection with your use of this Identity Verification Service.
          </Label>
          <Label className="mt-[10px] text-[15px] font-[Google Sans] font-[100] text-[#cbd4eb]">
            You agree to receive SMS messages for verification purposes.
            Standard message and data rates may apply.
          </Label>
          <Label className="mt-[10px] text-[15px] font-[Google Sans] font-[100] text-[#cbd4eb]">
            <a href="/" className="underline">
              Learn
            </a>{" "}
            how identity verification works.
          </Label>
        </div>
      </div>

      <div className="flex flex-col space-y-4 justify-center items-center pb-8 max-md:pb-[6rem]">
        <Button
          className="w-[65%] h-[43px] bg-[#ffffff] rounded-[24px] text-[16px] text-[#000] hover:opacity-90 hover:bg-[rgb(129,77,250)] hover:text-[#fff] transition-colors duration-500"
          onClick={() => onRegister()}
          disabled={disable || loader}
        >
          Agree and continue
        </Button>

        <Button
          className="w-[65%] text-[16px] hover:opacity-90 text-[#814dfa] bg-transparent underline"
          onClick={() => {
            loadPageConfiguration();
          }}
        >
          No Thanks
        </Button>
      </div>
    </div>
  );
};

export default UserIdentity;
