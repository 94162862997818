import PhoneOtp from "common/proveRegister/phoneOtp";
import { useState } from "react";

const OtpPhone = () => {
  const [step, setStep] = useState(1);

  const renderStep = () => {
    switch (step) {
      case 1:
        return <PhoneOtp setStep={setStep} />;
    }
  };
  return (
    <div className="bg-[#0c121c] backdrop-blur-md max-md:h-[calc(100vh_-80px)] relative overflow-hidden">
      <div className="max-md:w-[100%] w-[35%] m-auto h-[100vh] relative mb-[0px] max-md:h-[calc(100vh_-80px)]">
        {/* <UserConsent /> */}
        {renderStep()}
        {/* {showCamera && step === 1 && (
          <EnrollProve setStep={setStep} isEnroll={false} />
        )} */}
      </div>
    </div>
  );
};

export default OtpPhone;
