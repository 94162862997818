import { Label } from "../../components/ui/label";
import { Layout } from "../index";
import BackButton from "common/components/backButton";
import CloseButton from "common/components/closeButton";
import { UserContext } from "context/userContext";
import { useContext } from "react";
import mobileFrame from "assets/mobile.png";
import { Button } from "components/ui/button";

const ConfirmMobile = () => {
  const context: any = useContext(UserContext);
  const pageConfig: any = context?.pageConfiguration;
  const redirectUrl = `${window.location.origin}/predict-scan-intro?token=${context?.tokenParams}`;
  return (
    <Layout>
      <div className="relative flex text-center justify-center">
        <div className="relative w-full">
          <div className="px-10 py-8 max-md:p-[20px]">
            <div className="flex justify-between relative">
              <BackButton />
              <div className="flex items-center justify-center w-full" />
              <div>{/* {Empty div to adjust space} */}</div>
              <CloseButton />
            </div>
            <div className="mt-2">
              <Label className="text-[28px] font-[500] text-primaryText">
                {pageConfig?.title || `Confirm you phone number`}
              </Label>
            </div>
            <div className="flex flex-col w-[80%] m-auto max-md:w-[100%] max-md:p-[20px]">
              <Label className="mt-[0px] text-[20px] font-[Google Sans] font-[100] text-secondaryText">
                We sent a text to <b>{context?.user?.phone}</b>
              </Label>
              <img
                src={mobileFrame}
                alt="mobileFrame"
                className="w-[300px] m-auto"
              />
              <Label className="mt-[15px] text-[16px] font-[Google Sans] font-[100] text-secondaryText">
                {pageConfig?.subheading ||
                  `Click on the link to proceed. 
                  This link will expire in ten minutes.
                  `}
              </Label>
              <Button
                className="w-full text-white bg-primary rounded-[24px] mt-4 hover:opacity-90 hover:bg-primary"
                onClick={() => {}}
              >
                Update my phone number
              </Button>
              <Label className="mt-[15px] text-[14px] font-[Google Sans] font-[100] text-secondaryText block">
                <a
                  href={redirectUrl}
                  target={"_blank"}
                  className="text-primary hover:underline"
                >
                  Send a new link
                </a>
              </Label>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ConfirmMobile;
