import { useContext, useState } from "react";
import { Button } from "../../components/ui/button";
import { Label } from "../../components/ui/label";
import Layout from "../layout";
import BackButton from "../components/backButton";
import CloseButton from "common/components/closeButton";
import { UserContext } from "context/userContext";
import { useNavigation } from "utils/onNavigate";
import { CheckIcon, Edit2 } from "lucide-react";
import { areObjectsEqual } from "utils";
import { TetailsModal } from "common/components/detailsModal";

function ReviewDetails() {
  const context: any = useContext(UserContext);
  const pageConfig: any = context?.pageConfiguration;
  const { navigateToNextPage } = useNavigation();
  const [isFormDetail, setIsFormDetail] = useState(true);
  const [isEdit, setIsEdit] = useState(false);
  const [modal, setModal] = useState(false);
  const document = context?.user?.backDocumentData;
  const formDetails = context?.user?.userDetails;
  const documentDetail = {
    email: formDetails?.email,
    lastname: document?.lastName,
    firstname: document?.firstName,
    middlename: "",
    otherlastname: "",
    address: document?.address?.addressLine1,
    aptnumber: "",
    city: document?.address?.city,
    state: document?.address?.state,
    zipcode: document?.address?.zipCode,
    dob: document?.dob,
    phone: context?.user?.phone,
    ssn: formDetails?.ssn,
  };

  const userDetail =
    formDetails?.firstname && isFormDetail ? formDetails : documentDetail;
  const isSameDetails = formDetails?.firstname
    ? areObjectsEqual(formDetails, documentDetail)
    : true;
  const onSubmit = () => {
    setIsFormDetail(false);
    toggle();
  };
  const toggle = () => {
    setModal(!modal);
  };
  return (
    <>
      <Layout>
        <div className="relative flex text-center justify-center">
          <div className="relative w-full">
            <div className="px-10 py-8 max-md:p-[20px]">
              <div className="flex justify-between relative">
                <BackButton />
                <CloseButton />
              </div>
              <div className="mt-2">
                <Label className="text-[28px] font-[500] text-primaryText max-md:text-[24px]">
                  {pageConfig?.title || "I-9 Employment Verification"}{" "}
                </Label>
              </div>
              <div className="text-center overflow-auto h-[490px] mt-2 p-1">
                <div className="ps-8 pe-8">
                  <Label className="text-[14px] font-[400] text-secondaryText">
                    {pageConfig?.subheading ||
                      "Required for employment in the United States"}{" "}
                  </Label>
                </div>
                <div className="mt-2 relative">
                  <div>
                    <div className="w-[35%] py-[7px] max-md:w-[30%]">
                      <p className="text-[#7c7474] font-[400] text-right mb-[10px]">
                        Name
                      </p>
                      <p className="text-[#7c7474] font-[400] text-right mb-[10px]">
                        Other last names
                      </p>
                      <p className="text-[#7c7474] font-[400] text-right mb-[10px]">
                        Address
                      </p>
                      <p className="text-[#7c7474] font-[400] text-right mb-[10px]">
                        Date of birth
                      </p>
                      <p className="text-[#7c7474] font-[400] text-right mb-[10px]">
                        US SSN
                      </p>
                      <p className="text-[#7c7474] font-[400] text-right mb-[10px]">
                        Email
                      </p>
                      <p className="text-[#7c7474] font-[400] text-right mb-[10px]">
                        Phone
                      </p>
                    </div>
                    <div className="w-[55%] absolute right-[20px] top-0 border-2 border-[#ccc] py-[7px] px-[15px] rounded-[20px] max-md:w-[55%] max-md:right-[20px]">
                      <span
                        onClick={() => setIsEdit(!isEdit)}
                        className="bg-primary w-[35px] h-[35px] rounded-[20px] flex justify-center items-center absolute right-[-10px] top-[-10px] cursor-pointer"
                      >
                        {isEdit ? (
                          <CheckIcon size={20} color="#fff" />
                        ) : (
                          <Edit2 size={20} color="#fff" />
                        )}
                      </span>
                      <p className="text-[#000] font-[400] text-left mb-[10px]">
                        <input
                          type="text"
                          defaultValue={`${userDetail?.firstname ? `${userDetail?.firstname + userDetail?.middlename + userDetail?.lastname}` : '-'}`}
                          readOnly={!isEdit}
                          className={`outline-none w-full border-b-[1px]  ${
                            isEdit ? "border-[#000]" : "border-[#fff]"
                          }`}
                          aria-label={"username"}
                        />
                      </p>
                      <p className="text-[#000] font-[400] text-left mb-[10px]">
                        <input
                          type="text"
                          defaultValue={`${userDetail?.otherlastname || "-"}`}
                          readOnly={!isEdit}
                          className={`outline-none w-full border-b-[1px]  ${
                            isEdit ? "border-[#000]" : "border-[#fff]"
                          }`}
                          aria-label={"otherlastname"}
                        />
                      </p>
                      <p className="text-[#000] font-[400] text-left mb-[10px]">
                        <input
                          type="text"
                          defaultValue={`${userDetail?.address || "-"}`}
                          readOnly={!isEdit}
                          className={`outline-none w-full border-b-[1px]  ${
                            isEdit ? "border-[#000]" : "border-[#fff]"
                          }`}
                          aria-label={"address"}
                        />
                      </p>
                      <p className="text-[#000] font-[400] text-left mb-[10px]">
                        <input
                          type="text"
                          defaultValue={`${userDetail?.dob || "-"}`}
                          readOnly={!isEdit}
                          className={`outline-none w-full border-b-[1px]  ${
                            isEdit ? "border-[#000]" : "border-[#fff]"
                          }`}
                          aria-label={"dob"}
                        />
                      </p>
                      <p className="text-[#000] font-[400] text-left mb-[10px]">
                        <input
                          type="text"
                          defaultValue={`${userDetail?.ssn || "-"}`}
                          readOnly={!isEdit}
                          className={`outline-none w-full border-b-[1px]  ${
                            isEdit ? "border-[#000]" : "border-[#fff]"
                          }`}
                          aria-label={"ssn"}
                        />
                      </p>
                      <p className="text-[#000] font-[400] text-left mb-[10px] underline">
                        <input
                          type="email"
                          defaultValue={`${userDetail?.email || "-"}`}
                          readOnly={!isEdit}
                          className={`outline-none w-full border-b-[1px]  ${
                            isEdit ? "border-[#000]" : "border-[#fff]"
                          }`}
                          aria-label={"email"}
                        />
                      </p>
                      <p className="text-[#000] font-[400] text-left mb-[10px]">
                        <input
                          type="text"
                          defaultValue={`${userDetail?.phone || "-"}`}
                          readOnly={!isEdit}
                          className={`outline-none w-full border-b-[1px]  ${
                            isEdit ? "border-[#000]" : "border-[#fff]"
                          }`}
                          aria-label={"phone"}
                        />
                      </p>
                    </div>
                  </div>
                </div>
                {!isSameDetails && (
                  <Label className="text-[14px] font-[400] text-secondaryText w-[70%] m-auto mt-[30px] block">
                    Oops! Seems like the details on your ID do not match your
                    form details. kindly review your ID details{" "}
                    <span
                      className="cursor-pointer underline text-primary"
                      onClick={toggle}
                    >
                      here
                    </span>
                    .
                  </Label>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="text-left h-[100px] absolute w-full bottom-0 rounded-b-[24px] ps-10 pe-10 pt-6 pb-4 max-md:h-[unset]">
          <div>
            <Button
              className="w-full text-white bg-primary rounded-[24px] mt-4 hover:opacity-90 hover:bg-primary"
              onClick={() => {
                if (pageConfig) {
                  navigateToNextPage();
                }
              }}
            >
              {pageConfig?.buttonText || "Start I-9 Form"}
            </Button>
          </div>
        </div>
      </Layout>
      <TetailsModal
        open={modal}
        userDetail={documentDetail}
        onSubmit={onSubmit}
        toggle={toggle}
      />
    </>
  );
}

export default ReviewDetails;
