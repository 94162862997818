import { RouterProvider } from "react-router-dom";
import UserContextProvider from "./context/userContext";
import { router } from "./router";
import "./App.css";
import { Toaster } from "./components/ui/toaster";

function App() {
  return (
    <>
      <UserContextProvider>
        <RouterProvider router={router} />
      </UserContextProvider>
      <Toaster />
    </>
  );
}

export default App;
