import { useContext, useEffect } from "react";
import useCameraPermissions from "hooks/useCameraPermissions";
import { UserContext } from "context/userContext";
import Layout from "common/layout";
import BackButton from "common/components/backButton";
import CloseButton from "common/components/closeButton";
import { Label } from "components/ui/label";
import { Button } from "components/ui/button";
import { useNavigation } from "utils/onNavigate";

function InterviewCamera() {
  const context = useContext(UserContext);
  const pageConfig: any = context?.pageConfiguration;
  const { isCameraGranted } = useCameraPermissions(() => {});
  const { navigateToNextPage } = useNavigation();
  useEffect(() => {
    const startRecordingOnMount = async () => {
      const videoContainer: any = document.getElementById("userVideo");
      try {
        if (isCameraGranted) {
          const stream = await navigator.mediaDevices.getUserMedia({
            video: true,
            audio: true,
          });
          console.log(stream,'stream');
          
          videoContainer.srcObject = stream;
        }
      } catch (error: any) {
        console.error("Error accessing user media:", error);
        if (
          error.name === "NotAllowedError" ||
          error.name === "PermissionDeniedError"
        ) {
          alert(
            "Camera permission denied. Please grant permission to record videos."
          );
        }
      }
    };
    startRecordingOnMount();
  }, [isCameraGranted]);

  return (
    <Layout removeHeight={!isCameraGranted}>
      <div className="px-10 py-8 max-md:p-[20px] max-md:pt-[20px]">
        <div className="flex justify-between relative">
          <BackButton />
          <CloseButton />
        </div>
        <div className="mt-2">
          <Label className="text-[28px] font-[500] text-primaryText max-md:text-[24px]">
            {pageConfig?.title || "I-9 Employment Verification"}{" "}
          </Label>
        </div>
        <div className="mt-[50px] rounded-[20px] h-[292px] max-md:h-[unset] flex flex-col items-center justify-center">
          <video
            id="userVideo"
            muted
            autoPlay
            playsInline
            className={`w-full h-full 
                  object-cover`}
          />
        </div>
        <div className="text-left h-[100px] left-0 absolute w-full bottom-0 rounded-b-[24px] ps-10 pe-10 pt-6 pb-4 max-md:h-[unset]">
        <div>
          <Button
            className="w-full text-white bg-primary rounded-[24px] mt-4 hover:opacity-90 hover:bg-primary"
            onClick={() => {
              if (pageConfig) {
                navigateToNextPage();
              }
            }}
          >
            {pageConfig?.buttonText || "Do not close window"}
          </Button>
        </div>
      </div>
      </div>
    </Layout>
  );
}

export default InterviewCamera;
