import BackButton from "common/components/backButton";
import { Label } from "components/ui/label";
import "react-range-slider-input/dist/style.css";
import { Input } from "components/ui/input";
import { Button } from "components/ui/button";

type props = {
  setIsSigninForm: (e: boolean) => void;
  onChange: React.SyntheticEvent | Event | any;
  signinData: { employeeId: string; password: string };
};
function ContinuousAuthForm(props: props) {
  const { setIsSigninForm, onChange, signinData } = props;
  return (
    <div className="px-10 py-8 max-md:p-[20px] max-md:pt-[20px]">
      <div className="flex justify-between relative">
        <BackButton isCamera />
        <Button
          onClick={() => {
            setIsSigninForm(false);
          }}
          className="max-md:hidden text-[13px] p-[15px] py-[7px] h-[unset] text-white bg-primary rounded-[24px]  hover:opacity-90 hover:bg-primary absolute right-[-30px] top-[-5px]"
        >
          Login with face
        </Button>
      </div>
      <div className="mt-2">
        <Label className="text-[28px] font-[500] text-primaryText">
          {"Sign in"}{" "}
        </Label>
      </div>
      <div className="text-center overflow-auto h-[380px] mt-2 p-1 max-md:h-[unset]">
        <Label className="text-[14px] font-[400] text-primaryText text-left w-full block">
          Enter Employee ID
        </Label>
        <Input
          placeholder={"Employee ID"}
          className="h-[48px] mt-[12px] font-[400] rounded-[8px] border-borderSecondary placeholder:text-placeholder placeholder:font-normal text-[14px]"
          name={"employeeId"}
          onChange={onChange}
        />
        <Input
          placeholder={"Password"}
          className="h-[48px] mt-[12px] font-[400] rounded-[8px] border-borderSecondary placeholder:text-placeholder placeholder:font-normal text-[14px]"
          name={"password"}
          onChange={onChange}
          type="password"
        />
        <Label className="text-[14px] font-[400] text-primaryText text-left w-full block cursor-pointer mt-[10px]">
          Change Password
        </Label>
        <div>
          <Button
            className="w-full rounded-[24px] mt-4 hover:opacity-90 hover:bg-primary bg-[#eee] text-[#333] disabled:opacity-[0.4] opacity-[0.6] shadow-md disabled:shadow-[unset]"
            disabled={!signinData?.employeeId || !signinData?.password}
          >
            Sign in
          </Button>
        </div>
        <Button
          onClick={() => {
            setIsSigninForm(false);
          }}
          className=" text-[13px] p-[15px] py-[7px] h-[unset] text-white bg-primary rounded-[24px]  hover:opacity-90 hover:bg-primary hidden max-md:block max-md:m-[auto] max-md:mt-[10px] max-md:h-[40px]"
        >
          Login with face
        </Button>
      </div>
    </div>
  );
}

export default ContinuousAuthForm;
