import { Button } from "../../components/ui/button";
import { Label } from "../../components/ui/label";
import PasskeyBg from "../../assets/passkey-bg.svg";
import PasskeyErrorIcon from "../../assets/passkey-error.png";
import Stepper from "../faceScanningIntro/Stepper";
import Layout from "../layout";
import BackButton from "../components/backButton";
import { useNavigateWithQueryParams } from "../../utils/navigateWithQueryParams";
import {
  startAuthentication,
  startRegistration,
  browserSupportsWebAuthn
} from "@simplewebauthn/browser";
import {
  generateAuthenticationOptions,
  generateRegistrationOptions,
  verifyAuthentication,
  verifyRegistration,
} from "../../services/passkey";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "context/userContext";
import CloseButton from "common/components/closeButton";
import { useNavigation } from "utils/onNavigate";
import { useToast } from "components/ui/use-toast";
import { Loader2 } from "lucide-react";
import { getOrganizationFromURL } from "utils";

let loaded = false;
function CreatePasskey() {
  const context: any = useContext(UserContext);
  const pageConfig: any = context?.pageConfiguration;
  const projectName = getOrganizationFromURL();
  const { navigateWithQueryParams } = useNavigateWithQueryParams();
  const { navigateToNextPage } = useNavigation();
  const [supportsPasskey, setSupportsPasskey] = useState(false);
  const [loader, setLoader] = useState(false);
  const { toast } = useToast();
  const isPasskeySuccess = pageConfig?.type === "passkeySuccess";
  useEffect(() => {
    const checkWebAuthnSupport = async () => {
      try {
        if ("PublicKeyCredential" in window) {
          const supportsPasskey = browserSupportsWebAuthn();
          setSupportsPasskey(supportsPasskey);
          if (!loaded) {
            loaded = true;
            if (context?.configuration?.isKantara && !isPasskeySuccess) {
              onAuthenticatePasskey(supportsPasskey);
            }
          }
        } else {
          // WebAuthn is not supported
          setSupportsPasskey(false);
        }
      } catch (error) {
        console.error("Error checking WebAuthn support:", error);
        setSupportsPasskey(false);
      }
    };

    checkWebAuthnSupport();
  }, []);

  const onAuthenticatePasskey = async (supportsPasskey: boolean) => {
    if (!supportsPasskey) {
      if (pageConfig) {
        navigateToNextPage();
      } else {
        context.setSuccessMessage("Success! Your account is created");
        navigateWithQueryParams("/success");
      }
      return;
    }
    try {
      const uuid = JSON.parse(localStorage.getItem("uuid") ?? "{}");
      const response = await generateAuthenticationOptions(uuid);
      if (response?.challenge) {
        let asseResp;
        try {
          asseResp = await startAuthentication(response);
        } catch (error) {
          console.log({ error });
        }
        const verificationJSON = await verifyAuthentication({ asseResp, uuid });
        const alreadyEnroll = verificationJSON?.verified &&
          context?.user?.alreadyEnrolled &&
          pageConfig?.type === "enrollPasskey";
        if (verificationJSON?.verified) {
          context.setSuccessMessage("Success! Your account is created");
          if (alreadyEnroll) {
            navigateToNextPage("", "/success", "/generate-passkey");
          } else if (context?.configuration?.isKantara) {
            navigateToNextPage();
          } else if (pageConfig) {
            navigateToNextPage("", "/success", "/generate-passkey");
          } else {
            navigateWithQueryParams("/success");
          }
        } else {
          toast({
            variant: "destructive",
            description: "There was some issue authenticating with passkey.",
          });
        }
      }
    } catch (e) {
      toast({
        variant: "destructive",
        description: "There was some issue authenticating with passkey.",
      });
    }
  };
  const onGeneratePasskey = async () => {
    setLoader(true);
    if (isPasskeySuccess) {
      setLoader(false);
      if (context?.configuration?.isKantara) {
        navigateToNextPage();
      }
      return;
    }
    if (!context?.configuration?.isKantara) {
      if (!supportsPasskey) {
        context.setSuccessMessage("Success! Your account is created");
        setLoader(false);
        if (pageConfig) {
          navigateToNextPage();
        } else {
          navigateWithQueryParams("/success");
        }
        return;
      }
    }
    const uuid = JSON.parse(localStorage.getItem("uuid") ?? "{}");
    let response;
    try {
      response = await generateRegistrationOptions(uuid);
    } catch (error: any) {
      context.setSuccessMessage("Success! Your account is created");
      setLoader(false);
      if (context?.configuration?.isKantara) {
        navigateToNextPage();
      } else if (pageConfig) {
        navigateToNextPage();
      } else {
        navigateWithQueryParams("/success");
      }
      return;
    }
    let attResp;
    try {
      const opts = response;
      attResp = await startRegistration(opts);
    } catch (error: any) {
      context.setSuccessMessage("Success! Your account is created");
      setLoader(false);
      if (context?.configuration?.isKantara) {
        navigateToNextPage();
      } else if (pageConfig) {
        navigateToNextPage();
      } else {
        navigateWithQueryParams("/success");
      }
      return;
    }
    const verificationJSON = await verifyRegistration({ attResp, uuid });
    if (verificationJSON?.verified) {
      context.setSuccessMessage("Success! Your account is created");
      setLoader(false);
      if (context?.configuration?.isKantara) {
        navigateToNextPage();
      } else if (pageConfig) {
        navigateToNextPage();
      } else {
        navigateWithQueryParams("/success");
      }
    }
  };
  return (
    <Layout>
      <div className="relative flex text-center justify-center">
        <div className="relative w-full">
          <div className="px-10 py-8 max-md:p-[20px]">
            <div className="flex justify-between relative">
              <BackButton />
              <div className="flex items-center justify-center w-full">
                <Stepper step={3} />
              </div>
              <div></div>
              <CloseButton />
            </div>
            {supportsPasskey ? (
              <>
                <div className="mt-2">
                  <Label className="text-[28px] font-[500] text-primaryText">
                    {pageConfig?.title || "Create your Passkey"}{" "}
                  </Label>
                </div>
                <div className="text-center overflow-auto h-[470px] mt-2 p-1 max-md:h-[unset]">
                  {!isPasskeySuccess && (
                    <div className="ps-8 pe-8">
                      <Label className="text-[14px] font-[400] text-secondaryText">
                        {pageConfig?.subheading ||
                          "Speed up your sign in next time by creating a passkey on this device."}{" "}
                      </Label>
                    </div>
                  )}
                  <div className="mt-2">
                    <img
                      src={pageConfig?.image || PasskeyBg}
                      alt="PasskeyBg"
                      className="h-[240px] w-full rounded-[12px]"
                    />
                    <p className="text-[15px] font-[400 w-[93%] m-auto text-left mt-[30px] text-secondaryText max-md:w-[90%] max-md:text-left pr-[4rem]">
                      {isPasskeySuccess
                        ? "Next time you sign in with your Private ID you’ll use your fingerprint, face, or screen lock to verify it’s really you signing into your Private ID."
                        : "Passkeys enable you to securely sign in to your Private ID using your fingerprint, face, screen lock, or hardware security key."}{" "}
                      <a
                        href="https://support.google.com/accounts/answer/13548313?hl=en&visit_id=638454212621492829-547521705&p=passkey_leaf&rd=1"
                        target={"_blank"}
                        className="text-primary hover:underline"
                      >
                        Learn more
                      </a>
                    </p>
                  </div>
                </div>
              </>
            ) : (
              <div className="mt-2">
                <Label className="text-[28px] font-[500] text-primaryText">
                  {pageConfig?.title || "Create your Passkey"}{" "}
                </Label>
                <img
                  src={PasskeyErrorIcon}
                  alt="PasskeyErrorIcon"
                  className="w-[100px] m-auto block mt-[40px]"
                />
                <p className="text-[20px] font-[400] mt-[30px]">
                  Your browser is not currently supported. Please use a{" "}
                  <a
                    href="https://passkeys.dev/device-support/#matrix"
                    className="color-[#] text-primary"
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    recommended browser
                  </a>{" "}
                  or learn more{" "}
                  <a
                    href="https://passkeys.dev/device-support/"
                    className="color-[#] text-primary"
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    here
                  </a>
                  .
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="text-left h-[120px] absolute w-full bottom-0 rounded-b-[24px] ps-10 pe-10 pt-6 pb-4">
        <div>
          <Button
            className="w-full text-white bg-primary rounded-[24px] mt-4 hover:opacity-90 hover:bg-primary"
            onClick={() => onGeneratePasskey()}
            disabled={loader}
          >
            {loader ? <Loader2 className="animate-spin h-[20px] w-[20px] text-[#fff] m-auto" /> : supportsPasskey
              ? pageConfig?.buttonText || "Create a passkey"
              : "Continue"}
          </Button>
          {!isPasskeySuccess && (
            <Label
              className="hover:underline text-primary text-[14px] cursor-pointer mt-[0px] block text-center mb-0 mt-[10px]"
              onClick={() => {
                if (["aadhaar", "digi-in"].includes(projectName)) {
                  navigateToNextPage();
                } else if (pageConfig) {
                  navigateToNextPage("", "/verify-pin", "/generate-passkey");
                  // navigateToNextPage();
                } else {
                  navigateWithQueryParams("/verify-pin");
                  // navigateToNextPage();
                }
              }}
            >
              {pageConfig?.secondaryButton ||
                "No thanks, I am on a shared device."}
            </Label>
          )}
        </div>
      </div>
    </Layout>
  );
}

export default CreatePasskey;
