import userGrey from "assets/userAvatarGrey.png";
import helthGrey from "assets/healthGrey.png";
import calendarGrey from "assets/calendarGrey.png";
import storeOs from "assets/storeOs.png";
import { Button } from "components/ui/button";
import {
  extractRoute,
  isMobile,
  sliceUuid,
} from "utils";
import { useContext } from "react";
import { UserContext } from "context/userContext";
import { useNavigation } from "utils/onNavigate";
import Layout from "common/layout";

function StoreOs() {
  const context: any = useContext(UserContext);
  const configuration: any = context?.configuration;
  const { navigateToNextPage } = useNavigation();
  const uuid = JSON.parse(localStorage.getItem("uuid") || "{}");
  const loadPageConfiguration = () => {
    const currentRouteIndex = configuration?.appPages?.findIndex(
      (item: any) => item?.currentRoute === "/"
    );
    const activeIndex = currentRouteIndex;
    context?.setPageIndex(activeIndex);
    const pageConfiguration = extractRoute(configuration, activeIndex);
    context?.setPageConfiguration(pageConfiguration);
  };

  const onLogout = () => {
    localStorage.removeItem("uuid");
    localStorage.removeItem("user");
    loadPageConfiguration();
    navigateToNextPage("", "/", "/continuous-authentication-v2");
  };
  
  if (!uuid?.length) {
    onLogout()
    return <></>
  }

  const _renderScreen = () => {
    return (
      <>
        <div className={`relative mt-[-20px] max-md:mt-[10px]`}>
          <div className="bg-[#fff] p-[20px] rounded-[20px] shadow-lg h-[150px] flex items-center justify-center">
            <p className="text-[#ccc] text-[23px]">
              Welcome, <span className="text-[green]">{sliceUuid(uuid)}</span>
            </p>
          </div>
          <div className="flex items-center justify-between mt-[30px]  max-md:block mb-[20px]">
            <div className="bg-[#fff] p-[20px] rounded-[20px] shadow-lg h-[150px] w-[30%]  max-md:w-[100%]  max-md:mb-[30px] flex flex-col items-center justify-center">
              <img
                src={userGrey}
                className="w-[100px] m-auto mb-[10px] h-[60px] object-contain mt-[0px]"
                alt="user"
              />
              <p className="text-[#000] text-[13px] text-center">Me</p>
            </div>
            <div className="bg-[#fff] p-[20px] rounded-[20px] shadow-lg h-[150px] w-[30%]  max-md:w-[100%]  max-md:mb-[30px] flex flex-col items-center justify-center">
              <img
                src={calendarGrey}
                className="w-[100px] m-auto mb-[10px] h-[60px] object-contain mt-[0px]"
                alt="calendar"
              />
              <p className="text-[#000] text-[13px] text-center">My Shift</p>
            </div>
            <div className="bg-[#fff] p-[20px] rounded-[20px] shadow-lg h-[150px] w-[30%]  max-md:w-[100%]  max-md:mb-[30px] flex flex-col items-center justify-center">
              <img
                src={helthGrey}
                className="w-[100px] m-auto mb-[10px] h-[60px] object-contain mt-[0px]"
                alt="health"
              />
              <p className="text-[#000] text-[13px] text-center">Health</p>
            </div>
          </div>
          <div
            className="flex items-center mt-[10px]"
            style={{ marginTop: !isMobile ? "3rem" : 0 }}
          >
            <div className="text-[#aaa] justify-center w-[100%] flex items-center">
              UUID: {uuid?.length && uuid}
            </div>
          </div>
        </div>
      </>
    );
  };
  return (
    <>
      <Button
        className="w-[130px] bg-primary border-2 fixed right-8 top-5 max-md:top-[-2.8rem] max-md:absolute max-md:right-[0px] border-solid border-primary rounded-[24px] text-[14px] hover:opacity-90 hover:bg-primary text-[#fff] hover:text-white max-md:w-[100%] max-md:text-[13px] max-md:max-w-[80px] ml-[10px]"
        onClick={onLogout}
      >
        Logout
      </Button>
      <div
        style={{ backgroundColor: "#ededed" }}
        className={"h-[100vh] max-md:p-[10px] overflow-x-hidden"}
      >
        <Layout
          removeHeight={false}
          logo={storeOs}
          removeBorder={true}
          heightOverride={true}
          shadow={false}
          hideFooter={true}
        >
          <div id="experiment">
            <div id="cube" className="cube">
              <div id="side">{_renderScreen()}</div>
            </div>
          </div>
        </Layout>
      </div>
    </>
  );
}

export default StoreOs;
