import lock from "assets/lock.svg";
import useCameraPermissions from "hooks/useCameraPermissions";
import { useNavigateWithQueryParams } from "utils/navigateWithQueryParams";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "context/userContext";
import useFaceLoginWithLivenessCheck from "hooks/useFaceLoginWithLiveness";
import { generateOtp, getUser } from "services/api";
import {
  ACCOUNT_NOT_APPROVED,
  AUTHENTICATION_FAILED,
  ERROR,
  SUCCESS,
} from "constant";
import { getStatusFromUser } from "utils";
import Layout from "common/layout";
import BackButton from "common/components/backButton";
import CameraComponent from "common/components/camera";
import { useNavigation } from "utils/onNavigate";
import { useSearchParams } from "react-router-dom";
import { Label } from "components/ui/label";

function MobileLogin() {
  const context: any = useContext(UserContext);
  const pageConfig: any = context?.pageConfiguration;
  const { navigateWithQueryParams } = useNavigateWithQueryParams();
  const { navigateToNextPage } = useNavigation();
  const [showOtp, setShowOtp] = useState(false);
  const [pin, setPin] = useState("");
  const { isCameraGranted } = useCameraPermissions(() => {});
  const [completed, setCompleted] = useState(false);
  const onStatus = (e: number) => {};
  const onPredictFail = () => {
    if (context?.configuration?.isKantara) {
      navigateToNextPage("", "/predict-fail", "/predict-scan");
    }
  };
  const [searchParams] = useSearchParams();
  const tokenParams: any = searchParams.get("token");
  const {
    faceLoginWithLiveness,
    faceLoginWithLivenessMessage,
    faceLoginData,
    faceLoginResponseStatus,
    faceLoginInputImageData,
  } = useFaceLoginWithLivenessCheck(
    () => {},
    onStatus,
    50,
    false,
    onPredictFail
  );
  const onSuccess = () => {
    context.setUser({
      ...context.user,
      uuid: faceLoginData?.puid,
      guid: faceLoginData?.guid,
    });
    handelLoginResponse(faceLoginData);
  };

  const handelLoginResponse = async (result: any) => {
    if (result?.status === 0) {
      const payload = {
        guid: result?.guid,
        uuid: result?.puid,
        checkVC: false,
      };
      // This is for kantara flow
      if (
        context?.configuration?.isKantara &&
        window.location.pathname === "/predict-scan"
      ) {
        context.setUser({
          ...context.user,
          enrollImageData: faceLoginInputImageData,
        });
        navigateToNextPage();
        return;
      }
      const data: any = await getUser(payload);
      if (data?.data?.level === ERROR || data?.data?.statusCode === 404) {
        context.setFailedMessage(AUTHENTICATION_FAILED);
        navigateWithQueryParams("/failed");
        context.setUser({
          ...context.user,
          data,
        });
        return;
      }
      const user =
        data?.user || JSON.parse(localStorage.getItem("user") ?? "{}");
      if (user._id) {
        const userStatus = getStatusFromUser(data.status);
        if (userStatus === SUCCESS) {
          context.setUser({
            ...context.user,
            ...user,
          });
          context?.setSuccessMessage("Authentication successful!");
          onGenerateOtp(user);
        } else {
          context.setFailedMessage(ACCOUNT_NOT_APPROVED);
          navigateWithQueryParams("/failure");
        }
      }
    } else {
      context.setFailedMessage(AUTHENTICATION_FAILED);
      navigateWithQueryParams("/failed");
    }
  };

  const onGenerateOtp = async (user: { guid: string; uuid: string }) => {
    const payload = {
      uuid: user?.uuid,
      guid: user?.guid,
    };
    const result: { pin: string; success: boolean } | any = await generateOtp(
      tokenParams,
      payload
    );
    if (result?.success) {
      setShowOtp(true);
      setPin(result?.pin);
    }
  };

  useEffect(() => {
    if (faceLoginResponseStatus === 0) {
      setCompleted(true);
    }
  }, [faceLoginWithLivenessMessage]);

  const onCameraReady = () => {
    faceLoginWithLiveness();
  };

  return (
    <Layout removeHeight={!isCameraGranted}>
      <div className="px-10 py-8 max-md:p-[20px] max-md:pt-[20px]">
        <div className="flex justify-between relative max-md:p-0">
          <BackButton />
          <div className="bg-backgroundLightGray py-[5px] px-[15px] text-[12px] rounded-[20px] flex items-center m-auto">
            <img src={lock} alt="lock" className="mr-[5px]" />
            {pageConfig?.title || "Biometrics never leave this device"}
          </div>
        </div>
        <div className="mt-[50px] rounded-[20px]">
          <div className="relative">
            {showOtp ? (
              <>
                <Label className="text-[28px] font-[500] text-primaryText mb-[20px] block mt-[-20px]">
                  Login OTP
                </Label>
                <Label className="text-[80px] font-[500] text-primaryText mb-[20px] block mt-[40px]">
                  {pin}
                </Label>
              </>
            ) : (
              <CameraComponent
                faceLoginCamera={true}
                onCameraReady={onCameraReady}
                progress={faceLoginResponseStatus === 0 ? 99 : 0}
                message={faceLoginWithLivenessMessage}
                onSuccess={onSuccess}
                attempt={typeof faceLoginResponseStatus === "number" ? 1 : 0}
                scanCompleted={completed}
                onCameraSwitch={() => faceLoginWithLiveness()}
              />
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default MobileLogin;
