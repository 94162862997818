import loginFrame from "../../assets/login/login-main-frame.svg";
import { Label } from "../../components/ui/label";
import { Button } from "../../components/ui/button";
import { Layout } from "../index";
import { useNavigate } from "react-router-dom";
import { useNavigation } from "utils/onNavigate";
import { useContext } from "react";
import { UserContext } from "context/userContext";

const CharmLanding = () => {
  const context = useContext(UserContext);
  const navigate = useNavigate();
  const { navigateToNextPage } = useNavigation();

  const onRegister = async () => {
    if (context?.loginOption === "faceLogin") {
      navigateToNextPage("", "/face-login", "/");
    } else {
      navigateToNextPage('', `${'/user-consent'}`, "/");
    }
  };
  return (
    <Layout removeBorder={true} removeHeight={true}>
      <Label className="text-[28px] max-md:text-[20px] font-[Google Sans] font-[500] text-primaryText mb-[20px]">
        Take a selfie to register your age
      </Label>
      <div className="flex justify-center mt-0">
        <img
          src={loginFrame}
          alt=""
          width={window.innerWidth < 767 ? 250 : 400}
          className="md:min-h-[180px]"
        />
      </div>
      <div className="flex flex-col w-[80%] m-auto max-md:w-[100%] max-md:p-[20px]">
        <Label className="mt-[15px] text-[15px] font-[Google Sans] font-[100] text-secondaryText">
          By clicking the 'Agree and continue' button below, you acknowledge
          that you are over eighteen (18) years of age, have read the Private
          Identity{" "}
          <span
            onClick={() => {
              window.open(
                "https://www.centralams.com/privacy-policy/",
                "_blank"
              );
            }}
            className="text-primary underline cursor-pointer"
          >
            Privacy Policy
          </span>{" "}
          and{" "}
          <span
            onClick={() => {
              window.open("https://www.centralams.com/terms-of-use/", "_blank");
            }}
            className="text-primary underline cursor-pointer"
          >
            Terms of Use
          </span>{" "}
          , and understand how your personal data will be processed in
          connection with your use of the Age Estimation Service
        </Label>
        <Label className="mt-[15px] text-[15px] font-[Google Sans] font-[100] text-secondaryText">
          <span
            onClick={() => {
              navigate("/user-consent");
            }}
            className="text-primary underline cursor-pointer"
          >
            Learn
          </span>{" "}
          how age estimation works. No images leave your device.
        </Label>
        <div className="mt-10 flex justify-center max-md:mt-[20px]">
          <Button
            className="w-[65%] bg-primary rounded-[24px] text-[14px] hover:opacity-90 hover:bg-primary text-white max-md:w-[100%]"
            onClick={() => onRegister()}
          >
            Agree and continue
          </Button>
        </div>
        <div
          className="mt-4 flex justify-center max-md:mt-2"
          onClick={() => navigate(-1)}
        >
          <Button className="w-[65%] bg-primary rounded-[24px] text-[14px] hover:opacity-90 hover:bg-primary text-white max-md:w-[100%]">
            No Thanks
          </Button>
        </div>
      </div>
    </Layout>
  );
};

export default CharmLanding;
