import { Button } from "components/ui/button";
import { Label } from "components/ui/label";

const IntroScreen = ({ setStep, onsubmit, enrollValidationStatus }: any) => {
  return (
    <div className="pt-[3rem] bg-[#ffffff0a] h-full">
      <div className="mt-2">
        <Label className="text-[28px] font-[500] text-[#fff] text-center block">
          Set up Face ID
        </Label>
        <Label className="text-[14px] font-[400] text-[#fafafa] text-center block">
          Scan your face to verify your identity
        </Label>
        <div className="scanner-frame-intro">
          <div className="scanner-corner top-left"></div>
          <div className="scanner-corner top-right"></div>
          <div className="scanner-corner bottom-left"></div>
          <div className="scanner-corner bottom-right"></div>
          <div className="horizontal-line"></div>
        </div>
        <Label className="text-[17px] font-[400] text-[#fbfbfb] text-center block mb-[20px] opacity-70">
          {enrollValidationStatus || `Remove Glasses and look at camera`}
        </Label>
        <div className="mt-[70px] flex justify-center max-md:mt-16">
          {/* <Button
          className="w-[65%] bg-[#777] rounded-[24px] text-[15px] hover:opacity-90 hover:bg-[#777] border-2 border-[#fff] text-white"
          onClick={() => onsubmit()}
        //   disabled={disable}
        >
          Get started
        </Button> */}
          <Button
            className="w-[65%] h-[43px] bg-[#ffffff] rounded-[24px] text-[16px] text-[#000] hover:opacity-90 hover:bg-[rgb(129,77,250)] hover:text-[#fff] transition-colors duration-500"
            onClick={() => onsubmit()}
          >
            Continue
          </Button>
        </div>
        {/* <div className="mt-4 flex justify-center max-md:mt-2">
        <Button onClick={() => setStep(1)} className="w-[65%] bg-transparent rounded-[24px] text-[15px] hover:opacity-90 hover:bg-transparent border-2 border-[#fff] text-[#555]">
          Not now
        </Button>
      </div> */}
      </div>
    </div>
  );
};

export default IntroScreen;
