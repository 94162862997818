import { useContext, useState } from "react";
import { continuousPredictWithoutRestrictions } from "@privateid/cryptonets-web-sdk";
import { UserContext } from "context/userContext";
import { getStatusMessage } from "@privateid/cryptonets-web-sdk/dist/utils";

type SuccessCallback = (e: any) => void;

/**
 * Custom hook for continuous prediction using continuousAuthentication.
 *
 * @param {string} element - ID of the video element.
 * @param {function} onSuccess - Success callback function.
 * @param {function} onFailure - Failure callback function.
 * @param {function} onNotFound - Not found callback function.
 * @param {number} retryTimes - Number of retry times.
 * @returns {Object} - An object containing face detection status, prediction function, and prediction message.
 */
const useContinuousPredict = (
  element: string | undefined,
  onSuccess: SuccessCallback,
  onFailure: () => void,
  onNotFound: () => void,
  timeout?: number,
  retryTimes: number = 1
) => {
  const context: any = useContext(UserContext);
  const [faceDetected, setFaceDetected] = useState(false);
  const [continuousPredictMessage, setContinuousPredictMessage] = useState("");
  const [predictData, setPredictData] = useState("");

  let successCallback: SuccessCallback | null = null;
  let tries = 0;

  const predictUser = async (skipAntispoof = true) => {
    // @ts-ignore
    await continuousPredictWithoutRestrictions(
      callback,
      {
        skip_antispoof: true,
        threshold_high_vertical_predict: -0.2,
        threshold_down_vertical_predict: 0.2,
        threshold_user_up: 0.01,
        threshold_user_down: 0.99,
      } as any,
      undefined,
      // timeout
    );
  };

  const callback = async (result: any) => {
    console.log("CONTINUOUS AUTH CALLBACK", result);
    if (result?.face_validation_status === 0) {
      setContinuousPredictMessage("Success");
      setFaceDetected(true);
      if (successCallback) {
        successCallback(result);
      } else {
        setPredictData(result)
        onSuccess(result);
        setFaceDetected(true);
      }
      successCallback = null;
    } else {
      if (tries === retryTimes) {
        // stopTracks();
        onFailure();
      } else {
        tries += 1;
        // await predictUser();
      }
      if (result?.face_validation_status === -1) {
        setFaceDetected(false);
        setContinuousPredictMessage(
          getStatusMessage(result?.face_validation_status)
        );
        return;
      }
      if (result?.face_validation_status !== 0) {
        tries += 1;
        onFailure();
        setContinuousPredictMessage(
          getStatusMessage(result?.face_validation_status)
        );
        if (result?.face_validation_status === -7 && tries === retryTimes) {
          onNotFound();
        }
        if (tries === retryTimes) {
          // stopTracks();
          // onFailure(tries);
          return;
        } else {
          // await predictUser();
        }
      }
    }
  };
  
  return { faceDetected, predictUser, continuousPredictMessage, predictData };
};

export default useContinuousPredict;
