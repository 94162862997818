import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "../../../components/ui/dialog";
import { Button } from "components/ui/button";

type Props = {
  open: boolean;
  toggle?: () => void;
  userDetail?: any;
  onSubmit?: () => void;
}

export const TetailsModal = (props: Props) => {
  const { open, toggle, userDetail, onSubmit } = props;
  return (
    <Dialog open={open} onOpenChange={toggle}>
      <DialogContent className="p-3">
        <DialogHeader>
          <div className="flex max-w-[100%]">
            <div className="pt-[0px] w-full">
              <DialogTitle className="font-[400] text-[20px] text-center m-auto w-full block mb-[20px]">
                Are you sure you want to change these details?{" "}
              </DialogTitle>
              <DialogDescription>
                <div className="mt-2 relative">
                  <div>
                    <div className="w-[30%] py-[7px] max-md:w-[30%]">
                      <p className="text-[#a69e9e] font-[400] text-right mb-[10px]">
                        Name
                      </p>
                      <p className="text-[#a69e9e] font-[400] text-right mb-[10px]">
                        Other last names
                      </p>
                      <p className="text-[#a69e9e] font-[400] text-right mb-[10px]">
                        Address
                      </p>
                      <p className="text-[#a69e9e] font-[400] text-right mb-[10px]">
                        Date of birth
                      </p>
                      <p className="text-[#a69e9e] font-[400] text-right mb-[10px]">
                        US SSN
                      </p>
                      <p className="text-[#a69e9e] font-[400] text-right mb-[10px]">
                        Email
                      </p>
                      <p className="text-[#a69e9e] font-[400] text-right mb-[10px]">
                        Phone
                      </p>
                    </div>
                    <div className="w-[55%] absolute right-[30px] top-0 border-2 border-[#ccc] py-[7px] px-[15px] rounded-[20px] max-md:w-[55%] max-md:right-[20px]">
                      <p className="text-[#000] font-[400] text-left mb-[10px]">
                        {userDetail?.firstname} {userDetail?.middlename}
                        {userDetail?.lastname}
                      </p>
                      <p className="text-[#000] font-[400] text-left mb-[10px]">
                        {userDetail?.otherlastname || "-"}
                      </p>
                      <p className="text-[#000] font-[400] text-left mb-[10px]">
                        {userDetail?.address || "-"}
                      </p>
                      <p className="text-[#000] font-[400] text-left mb-[10px]">
                        {userDetail?.dob || "-"}
                      </p>
                      <p className="text-[#000] font-[400] text-left mb-[10px]">
                        {userDetail?.ssn || "-"}
                      </p>
                      <p className="text-[#000] font-[400] text-left mb-[10px] underline">
                        {userDetail?.email || "-"}
                      </p>
                      <p className="text-[#000] font-[400] text-left mb-[10px]">
                        {userDetail?.phone || "-"}
                      </p>
                    </div>
                  </div>
                </div>
              </DialogDescription>
              <div className="flex justify-around mt-[20px] mb-[10px]">
                <Button
                  className="w-[40%] text-white bg-[#fff] border border-primary text-primary rounded-[24px] mt-4 hover:opacity-90"
                  onClick={toggle}
                >
                  {"Cancel"}
                </Button>
                <Button
                  className="w-[40%] text-white bg-primary rounded-[24px] mt-4 hover:opacity-90 hover:bg-primary"
                  onClick={onSubmit}
                >
                  {"Change"}
                </Button>
              </div>
            </div>
          </div>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
};
