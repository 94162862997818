import { Button } from "../../components/ui/button";
import { Label } from "../../components/ui/label";

import Stepper from "../faceScanningIntro/Stepper";
import Layout from "../layout";
import { Checkbox } from "../../components/ui/checkbox";
import BackButton from "../components/backButton";
import { useNavigateWithQueryParams } from "../../utils/navigateWithQueryParams";
import {
  deleteCredentials,
  getCredentialDetails,
  issueCredentials,
  revokeCredentials,
  verifyCredentials,
  viewPDF,
} from "services/vc-dock";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "context/userContext";
import { useToast } from "components/ui/use-toast";
import { extractRoute } from "utils";
import { Loader2 } from "lucide-react";
import CloseButton from "common/components/closeButton";
import { useNavigation } from "utils/onNavigate";
import verticalDots from "../../assets/vertical-dots.png";
import { getUser } from "services/api";

type Props = {};

function VCProof(Props: Props) {
  const context: any = useContext(UserContext);
  const pageConfig: any = context?.pageConfiguration;
  const user: any =
    context?.user || JSON.parse(localStorage.getItem("user") ?? "{}");
  const userId = user?._id;
  const { navigateWithQueryParams } = useNavigateWithQueryParams();
  const { navigateToNextPage } = useNavigation();
  const [credentials, setCredentials] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const [loader, setLoader] = useState(false);
  const [menu, setMenu] = useState(false);
  const [menuLoader, setMenuLoader] = useState(false);
  const { toast } = useToast();
  const toggleMenu = () => {
    setMenu(!menu);
  };
  const viewCredentials = async () => {
    try {
      setCredentials({});
      setLoader(true);
      const credentialDetails: any = await getCredentialDetails(userId);
      if (credentialDetails?.id) {
        setCredentials(credentialDetails);
      }
      setLoader(false);
      return;
    } catch (e) {
      setLoader(false);
    }
  };

  const loadPageConfiguration = () => {
    const currentRouteIndex = context?.configuration?.appPages?.findIndex(
      (item: any) => item?.currentRoute === "/"
    );
    context?.setPageIndex(currentRouteIndex);
    const pageConfiguration = extractRoute(
      context?.configuration,
      currentRouteIndex
    );
    context?.setLoginOption("");
    context?.setPageConfiguration(pageConfiguration);
  };

  useEffect(() => {
    if (user) {
      viewCredentials();
    }
  }, [user]);
  const onSubmitPresentation = async () => {
    try {
      setLoading(true);
      const response: any = await verifyCredentials(userId);
      if (response.verified) {
        toast({
          variant: "success",
          description: "VC verified successfully.",
        });
        localStorage.setItem("credential", JSON.stringify(credentials));
        const inCheckoutFlow = context.loginOption === "checkoutVc";
        loadPageConfiguration();
        if (pageConfig) {
          if (inCheckoutFlow) {
            navigateWithQueryParams("/checkout");
          } else {
            navigateToNextPage("", "/", "/vd-proof");
          }
        } else {
          if (inCheckoutFlow) {
            navigateWithQueryParams("/checkout");
          } else {
            navigateWithQueryParams("/", { token: undefined });
          }
        }
      } else {
        toast({
          variant: "destructive",
          description: "There was some problem verifying VC. Please try again.",
        });
      }
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
      toast({
        variant: "destructive",
        description: "There was some problem verifying VC. Please try again.",
      });
    }
  };

  const onCancel = () => {
    localStorage.removeItem("credential");
    navigateWithQueryParams("/checkout");
  };

  const NoVcFound = () => {
    const generateCredentials = async () => {
      setLoader(true);
      try {
        const credentials: any = await issueCredentials(userId, false);
        setCredentials(credentials);
        const vcData = { did: credentials.did, credentialsId: credentials.id };
        localStorage.setItem("user", JSON.stringify({ ...user, ...vcData }));
        setLoader(false);
      } catch (error) {
        toast({
          variant: "destructive",
          description: "There was some problem verifying VC. Please try again.",
        });
        setLoader(false);
        loadPageConfiguration();
        if (pageConfig) {
          navigateToNextPage("", "/", "/vd-proof");
        } else {
          navigateWithQueryParams("/", { token: undefined });
        }
      }
    };
    return (
      <div
        className={"w-full h-full flex items-center justify-center flex-col"}
      >
        <h3 className={""}>
          {pageConfig?.notFound || "No Verifiable Credential available."}
        </h3>
        <Button
          className="w-[200px] text-white bg-primary rounded-[24px] mt-4 hover:opacity-90 hover:bg-primary"
          onClick={() => {
            generateCredentials();
          }}
        >
          {pageConfig?.notFoundButton || "Generate Now"}
        </Button>
      </div>
    );
  };
  const isLogin = ["checkoutVc", "loginVc"]?.includes(context.loginOption);

  const onViewCredential = async () => {
    setMenuLoader(true);
    toggleMenu();
    if (userId) {
      const response: any = await viewPDF(userId);
      const pdfUrl = URL.createObjectURL(
        new Blob([response.data], { type: "application/pdf" })
      );
      const newTab: any = window.open(pdfUrl, "_blank");
      // Make sure to revoke the URL object to release memory once the tab is closed
      newTab.onbeforeunload = () => URL.revokeObjectURL(pdfUrl);
      setMenuLoader(false);
    }
  };

  const onVerifyVC = async () => {
    setMenuLoader(true);
    toggleMenu();
    const response: any = await verifyCredentials(userId);
    if (response.verified) {
      toast({
        variant: "success",
        description: "VC verified successfully.",
      });
    } else {
      toast({
        variant: "destructive",
        description: "There was some problem verifying VC. Please try again.",
      });
    }
    setMenuLoader(false);
  };

  const onDeleteCredential = async () => {
    setMenuLoader(true);
    toggleMenu();
    await deleteCredentials(userId);
    toast({
      variant: "success",
      description: "VC deleted successfully.",
    });
    setCredentials({});
    setMenuLoader(false);
  };

  const onRevokeCredential = async () => {
    const payload = {
      id: userId,
    };
    const userResponse: any = await getUser(payload);
    setMenuLoader(true);
    toggleMenu();
    const revokePayload = {
      action: credentials?.revoked ? "unrevoke" : "revoke",
      credentialIds: [credentials?.credential?.id],
    };
    const result: any = await revokeCredentials(
      userResponse?.user?.registryId,
      revokePayload
    );
    setTimeout(() => {
      if (result?.data?.revokeIds?.length > 0) {
        viewCredentials();
        if (credentials?.revoked) {
          toast({
            variant: "success",
            description: "VC unrevoked successfully.",
          });
        } else {
          toast({
            variant: "success",
            description: "VC revoked successfully.",
          });
        }
        setMenuLoader(false);
      } else {
        setMenuLoader(false);
      }
    }, 2000);
  };

  const renderMenu = () => {
    return (
      <>
        {menuLoader ? (
          <Loader2 className="animate-spin h-[40px] w-[40px] text-[#5182ec] absolute right-[20px] top-[10px]" />
        ) : (
          <img
            src={verticalDots}
            alt="verticalDots"
            className="w-[20px] absolute right-[10px] cursor-pointer z-[999999] top-[20px]"
            onClick={toggleMenu}
          />
        )}
        {menu && (
          <div className="bg-white absolute right-[10px] top-[50px] rounded-[20px] overflow-hidden">
            <ul className="list-none">
              <li
                className="cursor-pointer px-[15px] py-[10px] hover:bg-gray-400 pt-[15px]"
                onClick={onViewCredential}
              >
                View Credential
              </li>
              <li
                className="cursor-pointer px-[15px] py-[10px] hover:bg-gray-400"
                onClick={onVerifyVC}
              >
                Verify Credential
              </li>
              <li
                className="cursor-pointer px-[15px] py-[10px] hover:bg-gray-400"
                onClick={onDeleteCredential}
              >
                Delete Credential
              </li>
              {!credentials?.revoked ? (
                <li
                  className="cursor-pointer px-[15px] py-[10px] hover:bg-gray-400"
                  onClick={onRevokeCredential}
                >
                  Revoke Credential
                </li>
              ) : (
                <li
                  className="cursor-pointer px-[15px] py-[10px] hover:bg-gray-400 pb-[15px]"
                  onClick={onRevokeCredential}
                >
                  Unrevoke Credential
                </li>
              )}
            </ul>
          </div>
        )}
      </>
    );
  };
  return (
    <Layout>
      <div className="relative flex text-center justify-center">
        <div className="relative w-full">
          <div className="px-10 py-8 max-md:p-[20px]">
            <div className="flex justify-between relative">
              <BackButton />
              <div className="flex items-center justify-center w-full">
                <Stepper step={isLogin ? 2 : 5} loginSteps={isLogin} />
              </div>
              <div>{/* {Empty div to adjust space} */}</div>
              <CloseButton />
            </div>
            <div className="mt-2">
              <Label className="text-[28px] font-[500] text-primaryText">
                {pageConfig?.title || "Verifiable Credentials"}{" "}
              </Label>
            </div>
            <div className="text-center overflow-auto h-[490px] mt-2 p-1">
              <div className="ps-8 pe-8">
                <Label className="text-[14px] font-[400] text-secondaryText">
                  {pageConfig?.subheading ||
                    "Please select the personal details you want to share."}
                </Label>
              </div>
              <div className="relative mt-5 bg-[#EBF3FE] h-[370px] rounded-[16px] text-left p-5 max-md:h-[320px] max-md:overflow-auto">
                {loader ? (
                  <div className="w-full h-full flex items-center justify-center">
                    <Loader2 className="animate-spin h-[55px] w-[55px] text-[#5182ec]" />
                  </div>
                ) : !credentials?.id || !credentials?.credential ? (
                  <NoVcFound />
                ) : (
                  <>
                    {renderMenu()}
                    <div className="flex flex-col">
                      <Label className="text-secondaryText font-[400] text-[14px] tracking-[0.5px]">
                        LAST NAME
                      </Label>
                      <Label className="text-primaryText font-[700] text-[14px] tracking-[0.5px]"></Label>
                    </div>
                    <div className="flex flex-col mt-[16px]">
                      <Label className="text-secondaryText font-[400] text-[14px] tracking-[0.5px]">
                        FIRST NAME
                      </Label>{" "}
                      <Label className="text-primaryText font-[700] text-[14px] tracking-[0.5px]"></Label>
                    </div>
                    <div className="flex flex-col mt-[16px]">
                      <Label className="text-secondaryText font-[400] text-[14px] tracking-[0.5px]">
                        EMAIL ADDRESS
                      </Label>
                      <Label className="text-primaryText font-[700] text-[14px] tracking-[0.5px]"></Label>
                    </div>
                    <div className="flex flex-col mt-[16px]">
                      <Label className="text-secondaryText font-[400] text-[14px] tracking-[0.5px]">
                        PHONE NUMBER
                      </Label>
                      <Label className="text-primaryText font-[700] text-[14px] tracking-[0.5px]"></Label>
                    </div>
                    <div className="flex flex-col mt-[16px]">
                      <Label className="text-secondaryText font-[400] text-[14px] tracking-[0.5px]">
                        BILLING ADDRESS{" "}
                      </Label>
                      <Label className="text-primaryText font-[700] text-[14px] tracking-[0.5px]"></Label>
                    </div>
                    <div className="flex flex-col mt-[16px]">
                      <Label className="text-secondaryText font-[400] text-[14px] tracking-[0.5px]">
                        SHIPPING ADDRESS
                      </Label>
                      <Label className="text-primaryText font-[700] text-[14px] tracking-[0.5px]"></Label>
                    </div>
                    <div className="flex flex-col mt-[16px]">
                      <Label className="text-secondaryText font-[400] text-[14px] tracking-[0.5px]">
                        CARD DETAILS{" "}
                      </Label>
                      <Label className="text-primaryText font-[700] text-[14px] tracking-[0.5px]"></Label>
                    </div>
                    <div className="flex flex-col mt-[16px]">
                      <Label className="text-secondaryText font-[400] text-[14px] tracking-[0.5px]">
                        IDENTITY TOKEN{" "}
                        </Label>
                        <Label className="text-primaryText font-[700] text-[14px] tracking-[0.5px]"></Label>
                      </div>
                    </>
                  )}
                </div>
                {credentials?.id && (
                  <div
                    onClick={() => setChecked(!checked)}
                    className="flex mt-[10px] relative z-[99999] cursor-pointer"
                  >
                    <Checkbox
                      className="bg-[#EBF3FE] mt-[2px] mr-[5px]"
                      onClick={() => setChecked(!checked)}
                      checked={checked}
                    />
                    <p className="text-[14px] text-left">
                      {pageConfig?.consentText ||
                        "I agree to the Private ID verifiable credentials terms and conditions."}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="text-left h-[120px] absolute  w-full bottom-0 rounded-b-[24px] ps-10 pe-10 pt-6 max-md:pt-0 pb-4">
          <div>
            <Button
              className="w-full text-white bg-primary rounded-[24px] mt-4 hover:opacity-90 hover:bg-primary"
              onClick={onSubmitPresentation}
              disabled={!checked || loading}
            >
              {pageConfig?.buttonText || "Share personal details"}
            </Button>
            <Label
              className="hover:underline text-primary text-[14px] cursor-pointer block text-center mb-0 mt-[10px]"
              onClick={() => {
                if (isLogin) {
                  onCancel();
                } else {
                  toast({
                    variant: "destructive",
                    description: "Permission Denied",
                  });
                  loadPageConfiguration();
                  if (pageConfig) {
                    navigateToNextPage("", "/", "/vd-proof");
                  } else {
                    navigateWithQueryParams("/", { token: undefined });
                  }
                }
              }}
            >
              {isLogin
                ? "Cancel"
                : pageConfig?.secondaryButton || "I do not agree"}
            </Label>
          </div>
        </div>
      </Layout>
  );
}

export default VCProof;
