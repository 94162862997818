import React, { useContext } from "react";
import { Label } from "components/ui/label";
import { useLocation, useSearchParams } from "react-router-dom";
import { useNavigation } from "utils/onNavigate";
import { UserContext } from "context/userContext";
import { useNavigateWithQueryParams } from "utils/navigateWithQueryParams";

const SwitchDeviceSelect = () => {
  const { navigateWithQueryParams } = useNavigateWithQueryParams();
  const location = useLocation();
  const { setPrevPageIndex, configuration, pageConfiguration }: any =
    useContext(UserContext);
  const pageConfig: any = pageConfiguration;
  const { navigateToNextPage } = useNavigation();
  const [searchParams] = useSearchParams();
  const params = `token=${searchParams.get("token")}${
    searchParams.get("skipAntispoof") ? "&skipAntispoof=true" : ""
  }`;
  const currentRouteIndex = configuration?.appPages?.findIndex(
    (item: any) => item?.currentRoute === window.location.pathname
  );
  return (
    <div className="mt-0 text-primary text-[14px] cursor-pointer block text-center mb-0 mt-[0px] max-lg:hidden">
      <Label className="font-[400] text-secondaryText text-[14px]">
        {pageConfig?.havingText || "Having problems?"}
      </Label>
      <Label
        className="font-[500] text-primary ml-[6px] cursor-pointer text-[14px] hover:underline"
        onClick={() => {
          if (pageConfig) {
            setPrevPageIndex(currentRouteIndex);
            navigateToNextPage(params, "/switch-device");
          } else {
            navigateWithQueryParams(`/switch-device`, {
              from: location.pathname,
            });
          }
        }}
      >
        {pageConfig?.switchText || "Switch to mobile"}
      </Label>
    </div>
  );
};

export default SwitchDeviceSelect;
