import { useContext, useState } from "react";
import lock from "assets/lock.svg";
import useCameraPermissions from "hooks/useCameraPermissions";
import { UserContext } from "context/userContext";
import {
  closeCamera,
} from "@privateid/cryptonets-web-sdk";
import Layout from "common/layout";
import BackButton from "common/components/backButton";
import CameraComponent from "common/components/camera";
import { ELEMENT_ID } from "constant";
import SwitchDeviceSelect from "common/components/switchDeviceSelect";
import { useNavigation } from "utils/onNavigate";
import useMultiFramePredictAge from "hooks/useMultiFramePredictAge";

function PredictAge() {
  const context = useContext(UserContext);
  const pageConfig: any = context?.pageConfiguration;
  const { navigateToNextPage } = useNavigation();
  const { isCameraGranted } = useCameraPermissions(() => {});
  const [scanCompleted, setScanCompleted] = useState(false);

  const onAgeSuccess = () => {
    setTimeout(async () => {
      await closeCamera(ELEMENT_ID);
      navigateToNextPage('', '/age-success', '/predict-age');
      setProgress(0);
    }, 2000);
  };

  const onSuccess = (resultAge: any, portrait: any) => {
    const userAge = Math.round(resultAge);
    context.setAge(userAge);
    setProgress(100);
    setScanCompleted(true);
  };
  const { doPredictAge, statusMessage, progress, setProgress } =
    useMultiFramePredictAge(onSuccess);
  const onCameraReady = () => {
    doPredictAge();
  };

  return (
    <Layout removeHeight={!isCameraGranted}>
      <div className="px-10 py-8 max-md:p-[20px] max-md:pt-[20px]">
        <div className="flex justify-between relative">
          <BackButton isCamera />
          <div className="bg-backgroundLightGray py-[5px] px-[15px] text-[12px] rounded-[20px] flex items-center m-auto">
            <img src={lock} alt="lock" className="mr-[5px]" />
            {pageConfig?.title || "Biometrics never leave this device"}
          </div>
        </div>
        <div className="mt-[50px] rounded-[20px] flex flex-col items-center justify-center">
          <CameraComponent
            faceCamera={true}
            onCameraReady={onCameraReady}
            progress={progress}
            message={statusMessage}
            onSuccess={onAgeSuccess}
            attempt={statusMessage ? 1 : 0}
            scanCompleted={scanCompleted}
          />
        </div>
      </div>
      <div className="mt-[-40px]">
        <SwitchDeviceSelect />
      </div>
    </Layout>
  );
}

export default PredictAge;
