import { useContext, useEffect, useState } from "react";
import useWasm from "hooks/useWasm";
import { getOrganizationFromURL, isMobile, stopCamera } from "utils";
import { CameraConfig, ELEMENT_ID, ENROLL_CANVAS_RESOLUTION } from "constant";
import {
  convertCroppedImage,
  updateTypeEnum,
  updateUserWithSession,
  verifyIdWithSession,
  verifySessionTokenV2,
  closeCamera, uploadEnrollImageWithSession,
} from "@privateid/cryptonets-web-sdk";
import deniedCameraIcon from "assets/deniedCamera.svg";
import cameraIcon from "assets/cameraIcon.svg";
import useCamera from "hooks/useCamera";
import useCameraPermissions from "hooks/useCameraPermissions";
import ProveLogo from "assets/proveLogo.png";
import PhoneInput from "common/components/phoneInput";
import { UserContext } from "context/userContext";
import { useNavigation } from "utils/onNavigate";
import { useNavigateWithQueryParams } from "utils/navigateWithQueryParams";
import FaceAnimation from "common/animateCircle/faceScanAnimation";
import Lottie from "lottie-react";
import completedCheck from "Animations/4-Capture-successfully/JSON/confetti.json";
import useEnrollOneFaWithLiveness from "hooks/useEnrollOneFaWithLiveness";
import { Button } from "components/ui/button";
import { AllowCameraModal } from "common/components/allowCameraModal";
import { Input } from "components/ui/input";
import {
  generateRegistrationOptions,
  verifyRegistration,
} from "services/passkey";
import { startRegistration } from "@simplewebauthn/browser";
import IntroScreen from "./introScreen";
import { issueCredentials } from "services/vc-dock";

const rendererSettings = {
  preserveAspectRatio: "xMaxYMin slice",
};

const INPUTS = [
  {
    type: "phone",
  },
  // {
  //   type: "dob",
  // },
];
let faceDetected = false;
let timeoutId: number | any;
const EnrollProve = ({
  setStep,
  isEnroll,
}: {
  setStep: (e: number) => void;
  isEnroll?: boolean;
}) => {
  const context: any = useContext(UserContext);
  const [deviceId, setDeviceId] = useState<string>();
  const [phone, setPhone] = useState<string>("");
  const [showPhone, setShowPhone] = useState(true);
  const projectName = getOrganizationFromURL();
  const { isCameraGranted, state }: any = useCameraPermissions(() => {});
  const { navigateToNextPage } = useNavigation();
  const [passkeyComleted, setPasskeyCompleted] = useState(false);
  const [minimizeCamera, setMinimizeCamera] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [isCompletedPredict, setIsCompletedPredict] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [overlay, setOverlay] = useState(false);

  // Camera and Wasm init
  const onCameraFail = () => {
    //   cameraFail?.();
  };
  const cameraReady = () => {
    //   onCameraReady?.();
    onCameraReady();
  };
  const documentScan = false;
  const { ready: wasmReady, wasmStatus } = useWasm();
  const canvasResolution =
    !isMobile && !documentScan ? ENROLL_CANVAS_RESOLUTION : null;
  const { ready, init, device, devices } = useCamera(
    CameraConfig?.elementId,
    documentScan ? "back" : (CameraConfig?.mode as any),
    CameraConfig?.requireHD,
    onCameraFail,
    documentScan,
    canvasResolution
  );
  useEffect(() => {
    if (device) {
      setDeviceId(device);
    }
  }, [device]);
  const handleWasmLoad = () => {
    if (!wasmReady && wasmStatus.isChecking) return;
    if (wasmReady && !wasmStatus.isChecking && wasmStatus.support) {
      if (!ready) {
        init();
      } else if (isCameraGranted && ready) {
        cameraReady();
      }
    }
    if (!wasmReady && !wasmStatus.isChecking && !wasmStatus.support) {
      onCameraFail();
    }
  };
  useEffect(() => {
    handleWasmLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wasmReady, ready, wasmStatus]);
  const onStatus = () => {};
  const onPredictFail = () => {
    if (context?.configuration?.isKantara) {
      navigateToNextPage("", "/predict-fail", "/predict-scan");
    }
  };

  const {
    enrollGUID,
    enrollPUID,
    enrollStatus,
    enrollValidationStatus,
    enrollUserOneFa,
    enrollImageData,
    progress,
  }: any = useEnrollOneFaWithLiveness(() => {});
  console.log(enrollStatus, "enrollStatus");

  function monitorEnrollStatus(enrollStatus: any) {
    // Clear the existing timeout if face is detected or enrollStatus changes
    if (enrollStatus === -1 && !faceDetected && !showPhone) {
      // Set a timeout that triggers after 3 seconds
      timeoutId = setTimeout(() => {
        if (!faceDetected) {
          faceDetected = true;
          setOverlay(true); // Change the step if no face is detected within 3 seconds
        }
      }, 3000);

      // Here, you can simulate face detection or enrollStatus change.
      const checkFaceInterval = setInterval(() => {
        // Assuming face detection logic happens here
        // If the face is detected or enrollStatus changes
        if (enrollStatus !== -1 || faceDetected) {
          setOverlay(true);
          clearTimeout(timeoutId); // Clear the timeout if the condition is met
          clearInterval(checkFaceInterval); // Stop checking further
        }
      }, 100); // Check every 100 milliseconds for changes
    }
  }

  monitorEnrollStatus(enrollStatus);
  useEffect(() => {
    if (isCompletedPredict && !showPhone) {
      setTimeout(() => {
        setCompleted(true);
        onFaceSuccess();
      }, 3000);
    }
  }, [isCompletedPredict, showPhone]);

  useEffect(() => {
    if (phone?.length >= 12) {
      setShowPhone(false);
    }
  }, [phone]);

  const handleAnimationComplete = (state: string) => {
    if (state === "start") {
      // setStartAnimation(true);
    } else if (state === "completed") {
      setCompleted(true);
      onFaceSuccess();
    }
  };

  useEffect(() => {
    if (enrollImageData && enrollGUID) {
      setIsCompletedPredict(true);
      //   onFaceSuccess();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enrollImageData, enrollGUID]);

  const alreadyEnrolled = enrollValidationStatus === "User Already Enrolled";
  const onFaceSuccess = async () => {
    await closeCamera(ELEMENT_ID);
    await stopCamera();
    setCompleted(true);
    context.setUser({
      ...context.user,
      uuid: enrollPUID,
      guid: enrollGUID,
      enrollImageData: enrollImageData,
      alreadyEnrolled,
    });
    localStorage.setItem("uuid", JSON.stringify(enrollPUID || {}));
    await updateUserWithSession({
      sessionToken: context?.tokenParams,
      updateType: updateTypeEnum.enroll,
      uuid: enrollPUID,
      guid: enrollGUID,
    });

    if (enrollImageData) {
      const enrollPortraitBase64 = await convertCroppedImage(
          enrollImageData.data,
          enrollImageData.width,
          enrollImageData.height
      );
      if (context?.configuration?.uploadToServer) {
        await uploadEnrollImageWithSession({
          sessionToken: context?.tokenParams,
          imageString: enrollPortraitBase64,
        });
      }
      localStorage.setItem("uuid", JSON.stringify(enrollPUID || {}));
    }
  };

  const onVerify = async () => {
    if (context?.isGenerateUuid) {
      context.setIsGenerateUuid(false);
      navigateToNextPage();
      return;
    }
    await verifyIdWithSession({
      sessionToken: context?.tokenParams,
    });
    const verifyTokenRes = await verifySessionTokenV2({
      sessionToken: context?.tokenParams,
    });
    enum tokenStatus {
      PENDING = "PENDING",
      SUCCESS = "SUCCESS",
      FAILURE = "FAILURE",
      REQUIRES_INPUT = "REQUIRES_INPUT",
    }
    if (verifyTokenRes.status === tokenStatus.SUCCESS) {
      // Success
      context.setSuccessMessage("Success! Your account is created");
      setPasskeyCompleted(true);
      await issueVC(verifyTokenRes.user, true);
    } else if (verifyTokenRes.status === tokenStatus.FAILURE) {
      setPasskeyCompleted(true);
    } else if (verifyTokenRes.status === tokenStatus.REQUIRES_INPUT) {
      setPasskeyCompleted(true);
    } else if (verifyTokenRes.status === tokenStatus.PENDING) {
      setPasskeyCompleted(true);
    }
  };

  const issueVC = async (userId: string, fullInformation: boolean) => {
    try {
      await issueCredentials(userId, fullInformation);
    } catch (e) {
      console.log({ e }, "error issueVC");
    }
  };
  const onGeneratePasskey = async () => {
    const uuid = enrollPUID || JSON.parse(localStorage.getItem("uuid") ?? "{}");
    let response;
    try {
      response = await generateRegistrationOptions(uuid);
    } catch (error: any) {
      onVerify();
      return;
    }
    let attResp;
    try {
      const opts = response;
      attResp = await startRegistration(opts);
    } catch (error: any) {
      context.setSuccessMessage("Success! Your account is created");
      onVerify();
      return;
    }
    const verificationJSON = await verifyRegistration({ attResp, uuid });
    if (verificationJSON?.verified) {
      onVerify();
    }
  };

  const onSuccess = async () => {
    setTimeout(async () => {
      if (window.location.pathname === "/register-mobile-with-id") {
        setStep(3);
      } else if (
        window.location.pathname === "/register-mobile-with-passport"
      ) {
        setStep(7);
      } else {
        onGeneratePasskey();
      }
    }, 2000);
  };

  useEffect(() => {
    if (completed) {
      onSuccess();
    }
  }, [completed]);

  const onCameraReady = () => {
    if (isEnroll) {
      enrollUserOneFa("", false, "collection_d", "test");
    }
  };

  return (
    <>
      {overlay && (
        <div className="absolute z-[99999999999999999] w-full h-full bg-[#0c121cb3] rounded-[20px] backdrop-blur-lg">
          <IntroScreen
            setStep={setStep}
            onsubmit={() => setOverlay(false)}
            enrollValidationStatus={enrollValidationStatus}
          />{" "}
        </div>
      )}
      {!isCameraGranted && state === "prompt" ? (
        <div className="pt-[20px] bg-[#595959] h-full flex items-center justify-center">
          <div className="bg-[#000] w-[80%] h-[300px] m-auto flex items-center justify-center flex-col rounded-[20px]">
            <img src={cameraIcon} alt="cameraIcon" />
            <p className="text-[28px] text-white max-w-[350px] text-center">
              Your browser will request access to your camera
            </p>
          </div>
        </div>
      ) : !isCameraGranted && state === "denied" ? (
        <div className="pt-[20px] bg-[#595959] h-full flex items-center justify-center">
          <div className="bg-[#000] w-[80%] h-[300px] m-auto flex items-center justify-center flex-col rounded-[20px]">
            <img src={deniedCameraIcon} alt="cameraIcon" />
            <p className="text-[28px] text-white max-w-[350px]">
              Can not access camera
            </p>
            <p className="text-[16px] text-placeholder max-w-[350px] font-[200]">
              Access to your camera has been denied.
            </p>
            <Button
              className="max-w-[140px] w-full text-[#333] bg-[#ccc] rounded-[24px] mt-4 hover:opacity-90 hover:bg-[#ccc]"
              onClick={() => setIsModalOpen(!isModalOpen)}
            >
              Allow camera
            </Button>
          </div>
        </div>
      ) : (
        <div className="bg-[#000] w-[100%] absolute top-[0px] left-[0px] overflow-hidden max-md:w-[100%] h-[100vh] max-md:top-[0px] max-md:h-[calc(100vh_-80px)] flex flex-col items-center justify-center">
          {showPhone && (
            <div className="absolute top-[0px] left-[0px] z-[99999] bg-[#0000006e] backdrop-blur-md h-[100%] w-[100%] rounded-[2px] flex items-center justify-center">
              <div className="relative w-[90%] max-md:w-[90%]">
                {INPUTS?.map((item) => {
                  if (item?.type === "phone") {
                    return (
                      <div className="mt-[-20px] provePhoneInput">
                        <PhoneInput
                          setValue={setPhone}
                          placeholder={"Phone Number"}
                          ariaLabel={"Phone Number"}
                          phone={phone}
                        />
                      </div>
                    );
                  } else if (item?.type === "dob") {
                    return (
                      <div className="mt-[20px] provePhoneInput dobInput">
                        <Input
                          type="date"
                          placeholder={"Enter you DOB"}
                          className={`mt-5 h-[56px] !p-[5px] rounded-[4px] placeholder:text-placeholder placeholder:font-normal`}
                        />
                      </div>
                    );
                  }
                })}

                <div
                  onClick={() => {
                    setShowPhone(false);
                    // faceLoginWithLiveness();
                  }}
                  className="bg-[#fff] text-[#000] w-[100px] h-[43px] mt-[10px] ml-auto rounded-[24px] flex justify-center items-center cursor-pointer text-[15px] hover:opacity-90 hover:text-[#fff] hover:bg-[rgb(129,77,250)] transition-colors duration-500"
                >
                  Register
                </div>
              </div>
            </div>
          )}

          <img
            src={ProveLogo}
            alt="ProveLogo"
            className="absolute top-[10px] z-[99999] max-md:right-[40%] right-[unset] left-[40%] max-md:left-[unset]"
          />

          {completed ? (
            <div className="bg-[#0e1026] w-[100%] h-[100%] flex items-center justify-center successAnimationNew">
              {/* // <div className="absolute h-full w-full top-0 bg-[#0c121c] flex items-center justify-center">
            //   <Lottie
            //     loop={false}
            //     autoplay={true}
            //     animationData={completedCheck}
            //     style={{
            //       height: isMobile ? 320 : "260px",
            //     }}
            //     rendererSettings={isMobile ? {} : rendererSettings}
            //   />
            // </div> */}
              <div className="text-[20px] absolute top-[62px] left-[38px] z-[99999999] w-full bg-transparent bg-opacity-70 text-white text-left py-2 rounded-lg">
                {passkeyComleted ? "Success" : "Done - Image Deleted "}
              </div>
              <div className="text-[14px] absolute top-[95px] left-[38px] z-[99999999] w-full bg-transparent bg-opacity-70 text-white text-left py-2 rounded-lg">
                From now on you can use your
                <br /> face to do things quicker
              </div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="svg-success"
                viewBox="0 0 24 24"
              >
                <g
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-miterlimit="10"
                >
                  <circle
                    className="success-circle-outline"
                    cx="12"
                    cy="12"
                    r="11.5"
                  />
                  <circle
                    className="success-circle-fill"
                    cx="12"
                    cy="12"
                    r="11.5"
                  />
                  <polyline
                    className="success-tick"
                    points="17,8.5 9.5,15.5 7,13"
                  />
                </g>
              </svg>
            </div>
          ) : (
            <>
              <FaceAnimation
                isCircle={minimizeCamera}
                isScanned={completed}
                handleAnimationComplete={handleAnimationComplete}
                fullScreen={true}
              >
                <div className="text-[20px] absolute top-[62px] left-[38px] z-[99999999] w-full bg-transparent bg-opacity-70 text-white text-left py-2 rounded-lg">
                  {enrollValidationStatus || "Center your head in the frame"}
                </div>
                <div className="text-[14px] absolute top-[95px] left-[38px] z-[99999999] w-full bg-transparent bg-opacity-70 text-white text-left py-2 rounded-lg">
                  Look directly into the camera
                  <br /> while we do the magic
                </div>
                <video
                  id="userVideo"
                  muted
                  autoPlay
                  playsInline
                  className={`w-full h-full face-camera object-cover`}
                />

                {!showPhone && (
                  <>
                    <div className="camera-container">
                      {/* Overlay for face recognition */}
                      <div className="camera-overlay">
                        {/* Five dots on the face */}
                        <div className="face-dot dot-eye-left"></div>
                        <div className="face-dot dot-eye-right"></div>
                        <div className="face-dot dot-nose"></div>
                        <div className="face-dot dot-mouth-left"></div>
                        <div className="face-dot dot-mouth-right"></div>
                        {/* Face recognition box */}
                        <div className="recognition-box">
                          {/* Animated corners */}
                          <div className="corner top-left"></div>
                          <div className="corner top-right"></div>
                          <div className="corner bottom-left"></div>
                          <div className="corner bottom-right"></div>
                        </div>
                      </div>
                    </div>
                    <div className="w-[90%] bg-gray-200 rounded-full h-1 mb-4 dark:bg-gray-700 absolute bottom-[58px] left-[21px] z-[9999999]">
                      <div
                        className="bg-[#000] h-1 rounded-full dark:bg-blue-500 transition-all"
                        style={{ width: `${progress || 0}%` }}
                      ></div>
                    </div>
                  </>
                )}
              </FaceAnimation>
              {/* <p className="text-[16px] absolute bottom-[10px] text-left px-[10px] z-[999999] text-[#fff]">
                By clicking Sign-in you agree to our terms of use and that you
                have read the Privacy Policy.{" "}
              </p> */}
            </>
          )}
        </div>
      )}

      <AllowCameraModal
        open={isModalOpen}
        toggle={() => setIsModalOpen(!isModalOpen)}
      />
    </>
  );
};

export default EnrollProve;
